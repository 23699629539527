import { Col } from "../Col";
import { Row } from "../Row";
import { Alert, Button, Dialog, DialogTitle, Divider } from "@mui/material";
import { useState } from "react";

const rowStyle = {
  paddingBottom: "10px",
  alignItems: "center",
};
const labelStyle = {
  minWidth: "120px",
  marginRight: "16px",
};

export const ConfirmationDialog = (props: {
  open: boolean;
  title: string;
  body: string;
  buttonColor:
    | "inherit"
    | "error"
    | "success"
    | "info"
    | "warning"
    | "primary"
    | "secondary"
    | undefined;
  buttonText: string;
  onConfirm: () => Promise<void>;
  onClose: () => void;
}) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const handleOnClose = () => {
    setErrorMessage(undefined);
    console.warn(errorMessage);
    props.onClose();
  };

  const onClickConfirm = async () => {
    setErrorMessage(undefined);
    try {
      await props.onConfirm();
    } catch (e) {
      console.error(e);
      setErrorMessage("処理に失敗しました。時間をおいて再度お試しください。");
    }
  };
  return (
    <Dialog
      open={props.open}
      onClose={() => {
        handleOnClose();
      }}
    >
      <DialogTitle>{props.title}</DialogTitle>
      <Divider
        sx={{
          marginLeft: "5%",
          marginRight: "5%",
        }}
      />
      <Col
        sx={{
          alignItems: "start",
          padding: "40px",
          width: "400px",
        }}
      >
        <Row sx={rowStyle}>
          <Row sx={labelStyle}>{props.body}</Row>
        </Row>
        {!!errorMessage ? (
          <Alert severity={"error"} sx={{ marginTop: "16px" }}>
            {errorMessage}
          </Alert>
        ) : (
          <div />
        )}
        <Row sx={{ marginTop: "20px" }}>
          <Button
            onClick={handleOnClose}
            variant={"outlined"}
            sx={{ marginRight: "20px" }}
          >
            キャンセル
          </Button>
          <Button
            onClick={onClickConfirm}
            variant={"contained"}
            color={props.buttonColor}
          >
            {props.buttonText}
          </Button>
        </Row>
      </Col>
    </Dialog>
  );
};
