import { Typography } from "@mui/material";
import React, { ComponentProps } from "react";

type Props = ComponentProps<typeof Typography>;
export const Label = (props: Props) => {
  return (
    <Typography variant={"h5"} sx={{ marginBottom: "16px" }}>
      {props.children}
    </Typography>
  );
};
