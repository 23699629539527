import { Button, Typography } from "@mui/material";
import React from "react";
import { Row } from "../../components/common/Row";
import { ResponsiveCol } from "../../components/common/ResponsiveCol";
import { getAnalytics, logEvent } from "firebase/analytics";
import { MAX_TENANT_KEY_LENGTH } from "@chatforce/common/src/constants/constants";
import { ValidatedTextField } from "../../components/common/elements/ValidatedTextField";
import { Card } from "../../components/common/elements/Card";

export const TenantSelectScreen = () => {
  const [tenantKey, setTenantKey] = React.useState<string>("");
  const [tenantKeyError, setTenantKeyError] = React.useState<boolean>(false);
  const host = window.location.host;
  const protocol = window.location.protocol;
  const analytics = getAnalytics();

  const hasError = (): boolean => {
    return tenantKeyError;
  };
  const onClickSubmit = async () => {
    logEvent(analytics, "clicked_submit_subdomain_button");
    if (tenantKey === "") {
      return;
    }
    window.location.href = `${protocol}//${tenantKey}.my.${host}/login`;
  };
  return (
    <ResponsiveCol
      className={"login-box"}
      sx={{
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Card
        withBorder
        filled
        sx={{
          padding: "40px",
          minWidth: "400px",
          alignItems: "center",
        }}
      >
        <Row>
          <Typography variant={"h1"} sx={{ marginBottom: "32px" }}>
            サブドメイン入力
          </Typography>
        </Row>
        <Row sx={{ alignItems: "center" }}>
          <Typography variant={"body1"}>{`${protocol}//`}</Typography>
          <ValidatedTextField
            type="text"
            variant="outlined"
            watchError={setTenantKeyError}
            value={tenantKey}
            onChange={(e) => {
              setTenantKey(e.target.value);
            }}
            inputProps={{ required: true, maxLength: MAX_TENANT_KEY_LENGTH }}
            sx={{
              backgroundColor: "white",
              borderRadius: "4px",
              width: "300px",
            }}
          />
          <Typography variant={"body1"}>.my.{host}</Typography>
        </Row>
        <Button
          onClick={onClickSubmit}
          variant="contained"
          size={"large"}
          sx={{ alignSelf: "center" }}
          style={{ marginTop: "24px" }}
          disabled={hasError()}
        >
          ログイン画面へ
        </Button>
        <Typography style={{ marginTop: "30px", textAlign: "center" }}>
          ※サブドメイン名がわからない場合は管理者にお問い合わせください。
        </Typography>
      </Card>
    </ResponsiveCol>
  );
};
