import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { configs } from "../../models/utils/Configs";
import { Col } from "../common/Col";
import { AppBar, Box, Drawer, IconButton, Toolbar } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Row } from "../common/Row";
import { DrawerContent } from "../menu/DrawerContent";
import { getAnalytics, logEvent } from "firebase/analytics";
import { Logo } from "../menu/Logo";

const drawerWidth = 250;

export const ServiceLayout = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const serviceName = configs.serviceName;
  const analytics = getAnalytics();
  const toggleDrawer = (open: boolean) => {
    setIsDrawerOpen(open);
    logEvent(analytics, "toggled_drawer", { open: open });
  };
  return (
    <Col
      sx={{
        height: "100%",
        justifyContent: "start",
      }}
    >
      {
        <AppBar
          position="sticky"
          sx={{
            zIndex: 1200,
            display: { xs: "block", md: "none" },
          }}
        >
          <Toolbar variant="dense">
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => {
                toggleDrawer(true);
                logEvent(analytics, "toggled_drawer", { open: true });
              }}
            >
              <MenuIcon />
            </IconButton>
            <Col
              sx={{
                alignItems: "start",
                justifyContent: "center",
                "&:hover": { cursor: "pointer" },
              }}
              onClick={() => {
                logEvent(analytics, "clicked_logo");
                navigate("/");
              }}
            >
              <Row sx={{ fontSize: "12px", fontWeight: "bold" }}>
                {serviceName}
              </Row>
              <Row sx={{ fontSize: "4px" }}>powered by GPT-4</Row>
            </Col>
            <Box sx={{ flexGrow: 1 }} />
          </Toolbar>
        </AppBar>
      }
      <Box
        component="nav"
        sx={{
          width: { md: drawerWidth },
          flexShrink: { md: 0 },
          background: "#F2F2F7",
        }}
        aria-label="mailbox folders"
      >
        <Drawer
          anchor={"left"}
          variant="temporary"
          open={isDrawerOpen}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              background: "#F2F2F7",
            },
          }}
          onClose={() => {
            toggleDrawer(false);
            logEvent(analytics, "toggled_drawer", { open: false });
          }}
        >
          <DrawerContent
            close={() => {
              toggleDrawer(false);
            }}
          />
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            width: "100px",
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth + 1,
              background: "#F2F2F7",
            },
          }}
          open
        >
          <Box
            sx={{ marginTop: "24px", marginLeft: "24px", marginBottom: "14px" }}
          >
            <Logo />
          </Box>
          <DrawerContent
            close={() => {
              toggleDrawer(false);
            }}
          />
        </Drawer>
      </Box>
      <Col
        className={"main-container"}
        sx={{
          width: { xs: "100%", md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
          height: "100%",
        }}
      >
        <Outlet />
      </Col>
    </Col>
  );
};
