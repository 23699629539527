import React, { ComponentProps, forwardRef, useEffect, useRef } from "react";
import TextField from "@mui/material/TextField";

type Props = ComponentProps<typeof TextField>;
type InputProps = Props & { watchError: (hasError: boolean) => void };
export const ValidatedTextField = forwardRef<HTMLInputElement, InputProps>(
  ({ watchError, ...props }: InputProps, ref) => {
    const [inputError, setInputError] = React.useState<boolean>(false);
    const [validationMessage, setValidationMessage] = React.useState<
      string | undefined
    >(undefined);
    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
      if (!!inputRef.current) {
        const ref = inputRef.current;
        const hasError = !ref.validity.valid;
        watchError(hasError);
      }
    }, []);
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!!inputRef.current) {
        const ref = inputRef.current;
        console.log(ref);
        const hasError = !ref.validity.valid;
        setInputError(hasError);
        watchError(hasError);
        setValidationMessage(inputRef?.current?.validationMessage);
      }
      if (props.onChange) {
        props.onChange(event);
      }
    };
    return (
      <TextField
        {...props}
        ref={ref}
        inputRef={inputRef}
        helperText={validationMessage}
        error={inputError}
        onChange={onChange}
        sx={{ ...props.sx }}
      ></TextField>
    );
  },
);
