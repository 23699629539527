import { Col } from "../../components/common/Col";
import React, { FormEvent, useEffect, useState } from "react";
import { Row } from "../../components/common/Row";
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from "recoil";
import { currentLoginTenantQuery } from "../../AppStates";
import {
  Alert,
  Box,
  Button,
  Divider,
  Snackbar,
  Typography,
} from "@mui/material";
import { UnexpectedErrorScreen } from "../Error/UnexpectedErrorScreen";
import { serverTimestamp } from "firebase/firestore";
import { TenantFirestoreService } from "../../models/services/tenantFirestoreService";
import { TenantDao } from "@chatforce/common/src/dao/firestoreDao";
import { Label } from "../../components/common/Label";
import { ResponsiveCol } from "../../components/common/ResponsiveCol";
import { getAnalytics, logEvent } from "firebase/analytics";
import {
  MAX_TENANT_NAME_LENGTH,
  MIN_TENANT_NAME_LENGTH,
} from "@chatforce/common/src/constants/constants";
import { ValidatedTextField } from "../../components/common/elements/ValidatedTextField";
import { Card } from "../../components/common/elements/Card";
import { IdAllowedIpAddressDao, IdTenantGroup } from "../../entities/entities";
import { TenantUserTable } from "./UserTable/TenantUserTable";
import { TenantGroupTable } from "./GroupTable/TenantGroupTable";
import { CustomPolicyUpload } from "./CustomPolicyUpload/CustomPolicyUpload";
import { ComponentPermissionGuard } from "../../components/common/ComponentPermissionGuard";
import { IpProtectionTable } from "./IpProtection/IpProtectionTable";
import { AdminApiClient } from "../../models/apiClients/adminApiClient";
import { configs } from "../../models/utils/Configs";

export const TenantAdminScreen = () => {
  const loginTenantState = useRecoilValue(currentLoginTenantQuery);
  const [displayNameState, setDisplayNameState] = React.useState(
    loginTenantState?.displayName ?? "",
  );
  const [groups, setGroups] = useState<IdTenantGroup[]>([]);
  const [displayNameInputError, setDisplayNameInputError] =
    React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(
    undefined,
  );
  const [openSaveSnackbar, setOpenSaveSnackbar] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(true);
  const [refreshIpBlockInfo, setRefreshIpBlockInfo] = useState<boolean>(true);
  const [refreshIpBlockSwitch, setRefreshIpBlockSwitch] =
    useState<boolean>(true);

  const enableIpBlock = configs.features.enableIpBlock;

  const tenantId = loginTenantState?.id;
  const tenantService = TenantFirestoreService.getInstance();
  const adminApiClient = AdminApiClient.getInstance();
  const refresher = useRecoilRefresher_UNSTABLE(currentLoginTenantQuery);
  useEffect(() => {
    if (tenantId === undefined) return;
    if (!refresh) return;
    tenantService.listTenantGroups(tenantId).then((groups) => {
      setGroups(groups);
      setRefresh(false);
    });
  }, [tenantService, refresh]);
  const analytics = getAnalytics();
  if (tenantId === undefined) return <UnexpectedErrorScreen />;

  const onChangeDisplayName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayNameState(event.target.value);
  };

  const hasError = (): boolean => {
    return displayNameInputError;
  };

  const onSubmit = async (e: FormEvent<any>) => {
    e.preventDefault();
    setErrorMessage(undefined);
    let updates: Partial<TenantDao> = {};
    if (displayNameState !== loginTenantState?.displayName) {
      updates = { ...updates, displayName: displayNameState };
    }
    console.log(updates);
    try {
      await tenantService.setTenant(tenantId, {
        ...updates,
        updatedAt: serverTimestamp(),
      });
      setOpenSaveSnackbar(true);
      refresher();
    } catch (e) {
      console.error(e);
      setErrorMessage("保存に失敗しました。時間をおいて再度お試しください。");
    }
    logEvent(analytics, "saved_tenant_profile");
    logEvent(analytics, "clicked_save_tenant_profile_button");
  };

  return (
    <Box
      sx={{
        width: "100%",
        alignItems: "start",
        justifyContent: "start",
      }}
    >
      <ResponsiveCol sx={{ width: "auto" }}>
        <ComponentPermissionGuard allowedRoles={["superAdmin", "serviceAdmin"]}>
          <Typography variant={"h1"} sx={{ marginBottom: "40px" }}>
            テナント設定
          </Typography>
          <Card filled withBorder>
            <form onSubmit={onSubmit}>
              <Col sx={{ marginBottom: "40px" }}>
                <Label>テナント名</Label>
                <Row>
                  <ValidatedTextField
                    type="text"
                    variant={"outlined"}
                    value={displayNameState}
                    inputProps={{
                      required: true,
                      minLength: MIN_TENANT_NAME_LENGTH,
                      maxLength: MAX_TENANT_NAME_LENGTH,
                    }}
                    onChange={onChangeDisplayName}
                    watchError={setDisplayNameInputError}
                    sx={{ width: "300px" }}
                  ></ValidatedTextField>
                </Row>
              </Col>
              <Col sx={{ alignItems: "start" }}>
                {!!errorMessage ? (
                  <Alert severity="error" sx={{ marginBottom: "16px" }}>
                    {errorMessage}
                  </Alert>
                ) : (
                  <div />
                )}
                <Button
                  type="submit"
                  variant="contained"
                  disabled={hasError()}
                >
                  保存
                </Button>
              </Col>
            </form>
          </Card>
          <Divider
            variant="middle"
            sx={{
              margin: 0,
              marginTop: "40px",
              marginBottom: "40px",
              width: "100%",
            }}
          />
        </ComponentPermissionGuard>
        <Typography variant={"h1"} sx={{ marginBottom: "40px" }}>
          ユーザー管理
        </Typography>
        <Label>ユーザー</Label>
        <TenantUserTable
          groups={groups}
          onUpdate={() => {
            setRefresh(true);
          }}
        />
        <Box sx={{ height: "40px" }} />
        <Label>グループ</Label>
        <TenantGroupTable
          groups={groups}
          onUpdate={() => {
            setRefresh(true);
          }}
        />
        <Divider
          variant="middle"
          sx={{
            margin: 0,
            marginTop: "40px",
            marginBottom: "40px",
            width: "100%",
          }}
        />
        <ComponentPermissionGuard allowedRoles={["superAdmin", "serviceAdmin"]}>
          <Typography variant={"h1"} sx={{ marginBottom: "40px" }}>
            カスタム利用規約の設定
          </Typography>
          <CustomPolicyUpload />
        </ComponentPermissionGuard>
        {enableIpBlock && (
          <ComponentPermissionGuard allowedRoles={["superAdmin"]}>
            <Divider
              variant="middle"
              sx={{
                margin: 0,
                marginTop: "40px",
                marginBottom: "40px",
                width: "100%",
              }}
            />
            <Typography variant={"h1"} sx={{ marginBottom: "40px" }}>
              許可IPアドレスリスト
            </Typography>
            <IpProtectionTable/>
          </ComponentPermissionGuard>
        )}
      </ResponsiveCol>
      <Snackbar
        open={openSaveSnackbar}
        autoHideDuration={6000}
        onClose={() => {
          setOpenSaveSnackbar(false);
        }}
      >
        <Alert severity="success" variant={"filled"} sx={{ width: "100%" }}>
          設定変更を保存しました。
        </Alert>
      </Snackbar>
    </Box>
  );
};
