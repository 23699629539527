import React, { useEffect } from "react";
import * as Sentry from '@sentry/react';

import "./App.scss";
import { ThemeProvider } from "@mui/material";

import { Route, Routes, useLocation } from "react-router-dom";
import { ChatScreen } from "./screens/Chat/ChatScreen";
import { Col } from "./components/common/Col";
import { LoginScreen } from "./screens/Login/LoginScreen";
import { getAnalytics, logEvent, setUserId } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { Terms } from "./screens/Legal/Terms";
import { Privacy } from "./screens/Legal/Privacy";
import { NotFoundScreen } from "./screens/Error/NotFoundScreen";
import { useSetRecoilState } from "recoil";
import { authState } from "./AppStates";
import { AuthGuard } from "./guards/AuthGuard";
import { AccountScreen } from "./screens/MyPage/AccountScreen";
import { TenantAdminScreen } from "./screens/TenantAdmin/TenantAdminScreen";
import { SignupScreen } from "./screens/Register/SignupScreen";
import { Settings } from "luxon";
import { connectAuthEmulator } from "@firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import { TenantSelectScreen } from "./screens/Login/TenantSelectScreen";
import { appTheme } from "./appTheme";
import { Cookies } from "./screens/Legal/Cookies";
import { TransactionsLaw } from "./screens/Legal/TransactionsLaw";
import { TopScreen } from "./screens/Top/TopScreen";
import { isTenantPage } from "./models/hooks/hooks";
import { PermissionGuard } from "./guards/PermissionGuard";
import { BaseLayout } from "./components/layout/BaseLayout";
import { ServiceLayout } from "./components/layout/ServiceLayout";
import CssBaseline from "@mui/material/CssBaseline";
import { ResetPasswordScreen } from "./screens/ResetPassword/ResetPasswordScreen";
import { TenantCustomPolicyGuard } from "./guards/TenantCustomPolicyGuard";
import { PolicyAgreementScreen } from "./screens/Login/PolicyAgreementScreen";
import { ResetPasswordUpdateScreen } from "./screens/ResetPassword/ResetPasswordUpdateScreen";
import { FileManagement } from "./screens/TenantAdmin/FileManagement";
import { AuditLogs } from "./screens/TenantAdmin/AuditLogs";
import { TokenUsage } from "./screens/TenantAdmin/TokenUsage/TokenUsageScreen";
import { configs } from "./models/utils/Configs";
import { getInitializedApp } from "./utils/firebaseUtils";
import { SignedOutScreen } from "./screens/Login/SignedOutScreen";
import { AboutScreen } from "./screens/About/About";

Settings.defaultZone = "Asia/Tokyo";

const app = getInitializedApp();

if (process.env.NODE_ENV === "development") {
  connectAuthEmulator(getAuth(app), "http://localhost:9099", {
    disableWarnings: true,
  });
  connectFirestoreEmulator(getFirestore(app), "localhost", 8080);
  connectStorageEmulator(getStorage(app), "localhost", 9199);
}
const analytics = getAnalytics(app);

// chat-force.com のような、ルートドメインの場合のルーティング
function RootDomainRoutes() {
  return (
    <Route path="" element={<BaseLayout />}>
      <Route path="" element={<TopScreen />} />
      <Route path="tenant_select" element={<TenantSelectScreen />} />
    </Route>
  );
}

const enableFileUpload = configs.features.enableFileUpload;

// coruscant.my.chat-force.com のような、テナントのサブドメインの場合のルーティング
function TenantDomainRoutes() {
  return (
    <>
      <Route path="" element={<AuthGuard />}>
        <Route path="policy_agreement" element={<PolicyAgreementScreen />} />
        <Route path="" element={<TenantCustomPolicyGuard />}>
          <Route path="" element={<ServiceLayout />}>
            <Route path="" element={<ChatScreen />} />
            {/* <Route path="c" element={<ChatScreen />} /> */}
            <Route path="c/:conversationId" element={<ChatScreen />} />
            <Route
              path=""
              element={
                <PermissionGuard
                  redirectTo="/login"
                  allowedRoles={["admin", "superAdmin"]}
                />
              }
            >
              <Route path="tenant" element={<TenantAdminScreen />} />
              {enableFileUpload && (
                <Route path="tenant/files" element={<FileManagement />} />
              )}
              <Route path="tenant/report" element={<TokenUsage />} />
              <Route path="tenant/audit-logs" element={<AuditLogs />} />
            </Route>
            <Route path="account" element={<AccountScreen />} />
          </Route>
        </Route>
      </Route>
      <Route path="" element={<BaseLayout />}>
        <Route path="signup" element={<SignupScreen />} />
        <Route path="login" element={<LoginScreen />} />
        <Route path="signedout" element={<SignedOutScreen />} />
        <Route path="reset_password" element={<ResetPasswordScreen />} />
        <Route
          path="reset_password_update"
          element={<ResetPasswordUpdateScreen />}
        />
      </Route>
    </>
  );
}

function App() {
  const setAuthState = useSetRecoilState(authState);
  useEffect(() => {
    const auth = getAuth(app);
    auth.useDeviceLanguage();
    auth.onAuthStateChanged(async (authUser) => {
      if (authUser !== null && authUser.tenantId !== null) {
        const tokenResult = await authUser.getIdTokenResult(true);
        setAuthState({
          uid: authUser.uid,
          tenantId: authUser.tenantId,
          customPolicyAgreedAt: tokenResult.claims.customPolicyAgreedAt as any,
          role: tokenResult.claims.role as any,
        });
        setUserId(analytics, authUser.uid);
        Sentry.setUser({ id: authUser.uid, tenantId: authUser.tenantId });
      } else {
        console.log("No auth");
        setAuthState(null);
        Sentry.setUser(null);
      }
    });
  }, []);
  const location = useLocation();
  useEffect(() => {
    const page_path = location.pathname + location.search;
    logEvent(analytics, "page_view", { page_path });
  }, [location]);
  return (
    <Col
      sx={{
        flex: 1,
        height: "100%",
        alignItems: "stretch",
        alignContent: "stretch",
        justifyContent: "start",
      }}
    >
      <Routes>
        {isTenantPage() ? TenantDomainRoutes() : RootDomainRoutes()}
        <Route path="" element={<BaseLayout />}>
          <Route path="terms" element={<Terms />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="cookies" element={<Cookies />} />
          <Route path="transactions_law" element={<TransactionsLaw />} />
          <Route path="about" element={<AboutScreen />} />
          <Route path="*" element={<NotFoundScreen />} />
        </Route>
      </Routes>
    </Col>
  );
}

const ThemedApp = () => {
  return (
    <ThemeProvider theme={appTheme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  );
};

export default Sentry.withProfiler(ThemedApp);
