import { Avatar, Box } from "@mui/material";
import { stringToColor } from "../../utils/stringToColor";

interface Props {
  imageOne: string | null | undefined;
  imageTwo?: string | null | undefined;
  uidOne?: string;
  uidTwo?: string;
  bigIconSize?: string;
  smallIconSize?: string;
}

export const Avatars = ({
  imageOne,
  uidOne,
  imageTwo = undefined,
  uidTwo = undefined,
  bigIconSize = "32px",
  smallIconSize = "26px",
}: Props) => {
  const haveSecondUser = imageTwo !== undefined;

  const getContainerSizes = () => {
    if (!haveSecondUser) {
      return bigIconSize;
    } else {
      const smallIconSizeNumber = parseInt(smallIconSize.replace("px", ""));
      return smallIconSizeNumber * 1.3;
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: getContainerSizes(),
        height: getContainerSizes(),
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
      }}
    >
      {haveSecondUser && (
        <Avatar
          src={imageTwo ?? undefined}
          sx={{
            width: smallIconSize,
            height: smallIconSize,
            position: "absolute",
            bgcolor: stringToColor(uidTwo ?? ""),
            top: 0,
            right: 0,
          }}
        />
      )}
      <Avatar
        src={imageOne ?? undefined}
        sx={
          haveSecondUser
            ? {
              width: smallIconSize,
              height: smallIconSize,
              position: "absolute",
              left: 0,
              bottom: 0,
              bgcolor: stringToColor(uidOne ?? ""),
              border: "1px solid #fff",
            }
            : {
              bgcolor: stringToColor(uidOne ?? ""),
              width: bigIconSize,
              height: bigIconSize,
            }
        }
      />
    </Box>
  );
};
