import { Col } from "../../../components/common/Col";
import { Row } from "../../../components/common/Row";
import {
  Alert,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { UserApiClient } from "../../../models/apiClients/userApiClient";

const rowStyle = {
  paddingBottom: "10px",
  alignItems: "center",
};

const formStyle = {
  maxWidth: "440px",
  minWidth: "440px",
  "& > *": {
    width: "100%",
  },
};
export const TemplateDialog = (props: {
  open: boolean;
  onSaved: () => void;
  onClose: () => void;
}) => {
  const [title, setTitle] = useState<string>("");
  const [prompt, setPrompt] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );

  const handleOnClose = () => {
    setErrorMessage(undefined);
    setTitle("");
    setPrompt("");
    props.onClose();
  };

  const onClickSave = async () => {
    setErrorMessage(undefined);
    try {
      const userApiClient = UserApiClient.getInstance();
      await userApiClient.createTemplate({
        title,
        prompt,
        isDraft: false,
      });

      props.onSaved();
      handleOnClose();
    } catch (e) {
      console.error(e);
      setErrorMessage("保存に失敗しました。時間をおいて再度お試しください。");
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={() => {
        handleOnClose();
      }}
    >
      <DialogTitle>カスタムプロンプトを入力</DialogTitle>
      <Divider
        sx={{
          marginLeft: "5%",
          marginRight: "5%",
        }}
      />
      <Col
        sx={{
          alignItems: "start",
          padding: "40px",
          gap: "16px",
        }}
      >
        <Row sx={rowStyle}>
          <Row sx={formStyle}>
            <TextField
              label={"タイトル"}
              value={title}
              onChange={(event) => {
                setTitle(event.target.value);
              }}
            />
          </Row>
        </Row>
        <Row sx={rowStyle}>
          <Row sx={formStyle}>
            <TextField
              multiline
              minRows={3}
              label={"プロンプト"}
              value={prompt}
              onChange={(event) => {
                setPrompt(event.target.value);
              }}
            />
          </Row>
        </Row>
        {!!errorMessage ? (
          <Alert severity={"error"} sx={{ marginTop: "16px" }}>
            {errorMessage}
          </Alert>
        ) : (
          <div />
        )}
        <Row
          sx={{ marginTop: "20px", width: "100%", justifyContent: "flex-end" }}
        >
          <Button
            onClick={handleOnClose}
            variant={"outlined"}
            sx={{ marginRight: "20px" }}
          >
            キャンセル
          </Button>
          <Button
            onClick={onClickSave}
            variant={"contained"}
            disabled={!!errorMessage}
          >
            保存
          </Button>
        </Row>
      </Col>
    </Dialog>
  );
};
