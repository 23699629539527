import { Row } from "../common/Row";
import { useNavigate } from "react-router-dom";

export const Logo = () => {
  const navigate = useNavigate();
  return (
    <Row
      onClick={() => navigate("/")}
      sx={{ "&:hover": { cursor: "pointer" } }}
    >
      <img height={"36px"} src={"/img/logo.svg"} alt={"logo"} />
    </Row>
  );
};
