import React from "react";
import { ServiceUserRole } from "@chatforce/common/src/dao/firestoreDao";
import { useRecoilValue } from "recoil";
import { currentTenantUserQuery } from "../../AppStates";

interface Props {
  children: React.ReactNode;
  allowedRoles: ServiceUserRole[];
}

export const ComponentPermissionGuard = (props: Props) => {
  return (
    <React.Suspense fallback={null}>
      <ComponentPermissionGuardContent {...props} />
    </React.Suspense>
  );
};
const ComponentPermissionGuardContent = (props: Props) => {
  const user = useRecoilValue(currentTenantUserQuery);
  if (user === null || user === undefined) {
    return null;
  }
  if (!user.role || !props.allowedRoles.includes(user.role)) {
    return null;
  }
  return <>{props.children}</>;
};
