import { Col } from "../../components/common/Col";
import { Box, Button } from "@mui/material";
import { getAuth, signOut } from "firebase/auth";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

export const UnexpectedErrorScreen = () => {
  const onClickLogout = () => {
    const auth = getAuth();
    const navigate = useNavigate();
    signOut(auth)
      .then(() => {
        console.log("successfully signed out");
        navigate("/signedout", { replace: true });
      })
      .catch((error) => {
        console.error("failed to sign out", error);
      });
  };
  return (
    <Col
      sx={{
        flex: 1,
        height: "100%",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <Box>
        エラーが発生しました。再度ログインしても直らない場合は管理者にお問い合わせください。
      </Box>
      <Link to={"/"} style={{ textDecoration: "none" }}>
        <Button>トップに戻る</Button>
      </Link>
      <Button onClick={onClickLogout}>ログアウトする</Button>
    </Col>
  );
};


