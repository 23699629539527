import { Col } from "../../../components/common/Col";
import { Row } from "../../../components/common/Row";
import { Alert, Button, Dialog, DialogTitle, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { IdTenantGroup } from "../../../entities/entities";
import { AdminApiClient } from "../../../models/apiClients/adminApiClient";
import { getAnalytics, logEvent } from "firebase/analytics";
import {
  MAX_GROUP_NAME_LENGTH,
  MIN_GROUP_NAME_LENGTH,
} from "@chatforce/common/src/constants/constants";
import { ValidatedTextField } from "../../../components/common/elements/ValidatedTextField";

const rowStyle = {
  paddingBottom: "10px",
  alignItems: "center",
};
const labelStyle = {
  minWidth: "120px",
  marginRight: "16px",
  fontWeight: "bold",
};

const formStyle = {
  maxWidth: "240px",
  minWidth: "240px",
  "& > *": {
    width: "100%",
  },
};
export const EditGroupDialog = (props: {
  group: IdTenantGroup | null;
  onSaved: () => void;
  onClose: () => void;
}) => {
  const [groupName, setGroupName] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const [groupNameInputError, setGroupNameInputError] =
    useState<boolean>(false);
  const analytics = getAnalytics();

  useEffect(() => {
    if (props.group === null) return;
    setGroupName(props.group.displayName ?? "");
  }, [props.group]);

  const hasError = (): boolean => {
    return groupNameInputError;
  };
  const handleOnClose = () => {
    setGroupName("");
    setErrorMessage(undefined);
    console.log(errorMessage);
    props.onClose();
  };

  const onClickSave = async () => {
    if (!props.group) {
      return;
    }
    setErrorMessage(undefined);
    try {
      const adminClient = AdminApiClient.getInstance();
      await adminClient.updateTenantGroup(props.group.id, groupName);
      props.onSaved();
      handleOnClose();
      logEvent(analytics, "saved_tenant_group");
    } catch (e) {
      console.error(e);
      setErrorMessage("保存に失敗しました。時間をおいて再度お試しください。");
    }
  };
  return (
    <Dialog
      open={props.group !== null}
      onClose={() => {
        handleOnClose();
      }}
    >
      <DialogTitle>グループ編集</DialogTitle>
      <Divider
        sx={{
          marginLeft: "5%",
          marginRight: "5%",
        }}
      />
      <Col
        sx={{
          alignItems: "start",
          padding: "40px",
        }}
      >
        <Row sx={rowStyle}>
          <Row sx={labelStyle}>グループ名</Row>
          <Row sx={formStyle}>
            <ValidatedTextField
              type={"text"}
              value={groupName}
              inputProps={{
                required: true,
                minLength: MIN_GROUP_NAME_LENGTH,
                maxLength: MAX_GROUP_NAME_LENGTH,
              }}
              sx={{
                width: "300px",
              }}
              onChange={(event) => {
                setGroupName(event.target.value);
              }}
              watchError={setGroupNameInputError}
            />
          </Row>
        </Row>
        {!!errorMessage ? (
          <Alert severity={"error"} sx={{ marginTop: "16px" }}>
            {errorMessage}
          </Alert>
        ) : (
          <div />
        )}
        <Row sx={{ marginTop: "20px" }}>
          <Button
            onClick={handleOnClose}
            variant={"outlined"}
            sx={{ marginRight: "20px" }}
          >
            キャンセル
          </Button>
          <Button
            onClick={onClickSave}
            variant={"contained"}
            disabled={hasError()}
          >
            変更
          </Button>
        </Row>
      </Col>
    </Dialog>
  );
};
