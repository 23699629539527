import { Col } from "../../../components/common/Col";
import { Row } from "../../../components/common/Row";
import TextField from "@mui/material/TextField";
import {
  Alert,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  MenuItem,
} from "@mui/material";
import { ChangeEvent, useState } from "react";
import {
  IdTenantGroup,
  IdTenantUser,
  roleLabels,
} from "../../../entities/entities";
import { AdminApiClient } from "../../../models/apiClients/adminApiClient";
import {
  ServiceUserRole,
  TenantUserRole,
} from "@chatforce/common/src/dao/firestoreDao";
import { useRecoilValueLoadable } from "recoil";
import { currentTenantUserQuery } from "../../../AppStates";
import { getAnalytics, logEvent } from "firebase/analytics";
import {
  MAX_EMAIL_LENGTH,
  MAX_USER_NAME_LENGTH,
} from "@chatforce/common/src/constants/constants";
import { ValidatedTextField } from "../../../components/common/elements/ValidatedTextField";

const rowStyle = {
  paddingBottom: "10px",
  alignItems: "center",
};
const labelStyle = {
  minWidth: "120px",
  marginRight: "16px",
  fontWeight: "bold",
};

const formStyle = {
  maxWidth: "240px",
  minWidth: "240px",
  "& > *": {
    width: "100%",
  },
};
export const InviteUserDialog = (props: {
  tenantId: string;
  groups: IdTenantGroup[];
  tenantUsers: IdTenantUser[];
  open: boolean;
  onClose: () => void;
  totalSeats: number;
  usedSeats: number;
}) => {
  const loginUser = useRecoilValueLoadable(currentTenantUserQuery);
  const [displayName, setDisplayName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [group, setGroup] = useState<string>("");
  const [displayNameInputError, setDisplayNameInputError] =
    useState<boolean>(false);
  const [emailInputError, setEmailInputError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const [role, setRole] = useState<TenantUserRole>("user");
  const availableSeats = props.totalSeats - props.usedSeats;
  const analytics = getAnalytics();

  const hasError = (): boolean => {
    return displayNameInputError || emailInputError;
  };
  const onChangeGroup = (event: ChangeEvent<{ value: unknown }>) => {
    setGroup(event.target.value as string);
  };
  const onChangeRole = (event: ChangeEvent<{ value: unknown }>) => {
    setRole(event.target.value as TenantUserRole);
  };
  const handleOnClose = () => {
    setDisplayName("");
    setEmail("");
    setGroup("");
    setErrorMessage(undefined);
    props.onClose();
  };
  const onClickInvite = async () => {
    setErrorMessage(undefined);
    const adminApiClient = AdminApiClient.getInstance();
    if (
      props.tenantUsers.filter((user) => user.userEmail === email).length > 0
    ) {
      setErrorMessage(
        "すでに同じメールアドレスのメンバーが登録あるいは招待されています。",
      );
      return;
    }
    await adminApiClient
      .inviteUser(email, displayName, group, role, false)
      .then((id) => {
        console.log("Invitation succeeded");
        logEvent(analytics, "invited_tenant_user");
        handleOnClose();
      })
      .catch((err) => {
        setErrorMessage("招待に失敗しました。時間をおいて再度試してください。");
        console.error(err);
      });
  };
  if (loginUser.state !== "hasValue") {
    return <></>;
  }
  let roleOptions: ServiceUserRole[] = [];
  const userRole = loginUser.getValue()?.role;
  if (userRole === "admin") {
    roleOptions = ["admin", "user"];
  }
  if (userRole === "superAdmin" || userRole === "serviceAdmin") {
    roleOptions = ["superAdmin", "admin", "user"];
  }

  let availableSeatsText;
  if (availableSeats >= 0) {
    availableSeatsText = `あと${availableSeats}人登録可能です（現在${props.usedSeats}人／最大${props.totalSeats}人）`;
  } else {
    availableSeatsText = `登録できません`;
  }

  return (
    <Dialog
      open={props.open}
      onClose={() => {
        handleOnClose();
      }}
    >
      <DialogTitle>ユーザー追加</DialogTitle>
      <Divider
        sx={{
          marginLeft: "5%",
          marginRight: "5%",
        }}
      />
      <Col
        sx={{
          alignItems: "start",
          padding: "24px",
        }}
      >
        <Row sx={{ marginBottom: "16px" }}>
          {availableSeatsText}
        </Row>
        {availableSeats <= 0 && (
          <Row sx={{ color: "red", fontSize: "small", marginBottom: "16px" }}>
            招待可能数が上限に達しています。
            <br />
            さらにユーザーを招待するにはライセンスを追加購入してください。
          </Row>
        )}
        <Row sx={rowStyle}>
          <Row sx={labelStyle}>ユーザー名</Row>
          <Row sx={formStyle}>
            <ValidatedTextField
              type={"text"}
              value={displayName}
              inputProps={{ maxLength: MAX_USER_NAME_LENGTH }}
              onChange={(event) => {
                setDisplayName(event.target.value);
              }}
              watchError={setDisplayNameInputError}
              sx={{
                width: "300px",
              }}
            />
          </Row>
        </Row>
        <Row sx={rowStyle}>
          <Row sx={labelStyle}>メールアドレス</Row>
          <Row sx={formStyle}>
            <ValidatedTextField
              type={"email"}
              value={email}
              inputProps={{ maxLength: MAX_EMAIL_LENGTH }}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              watchError={setEmailInputError}
              sx={{
                maxWidth: "400px",
              }}
            />
          </Row>
        </Row>
        <Row sx={rowStyle}>
          <Row sx={labelStyle}>グループ</Row>
          <Row sx={formStyle}>
            <TextField
              select
              id="group-select"
              value={group}
              onChange={onChangeGroup}
            >
              {props.groups.map((group) => {
                return (
                  <MenuItem key={group.id} value={group.id} sx={formStyle}>
                    {group.displayName}
                  </MenuItem>
                );
              })}
            </TextField>
          </Row>
        </Row>
        <Row sx={rowStyle}>
          <Row sx={labelStyle}>権限</Row>
          <Row sx={formStyle}>
            <TextField
              select
              id="role-select"
              value={role}
              onChange={onChangeRole}
            >
              {roleOptions.map((role) => {
                return (
                  <MenuItem value={role} sx={formStyle} key={role}>
                    {roleLabels[role as TenantUserRole] ?? ""}
                  </MenuItem>
                );
              })}
            </TextField>
          </Row>
        </Row>
        {errorMessage !== undefined && (
          <Alert severity={"error"} sx={{ marginTop: "16px" }}>
            {errorMessage}
          </Alert>
        )}
        <Row sx={{ marginTop: "20px" }}>
          <Button
            onClick={handleOnClose}
            variant={"outlined"}
            sx={{ marginRight: "20px" }}
          >
            キャンセル
          </Button>
          <Button
            onClick={onClickInvite}
            variant={"contained"}
            disabled={availableSeats <= 0 || hasError()}
          >
            招待メール送信
          </Button>
        </Row>
      </Col>
    </Dialog>
  );
};
