import { Box } from "@mui/material";
import { Col } from "../../components/common/Col";
import { ChatforceSpinner } from "../../components/common/ChatforceSpinner";

export const LoadingScreen = () => {
  return (
    <Col
      sx={{
        flex: 1,
        height: "100vh",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <Box>
        <ChatforceSpinner />
      </Box>
    </Col>
  );
};
