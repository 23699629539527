import { ComponentProps } from "react";
import { Button, CircularProgress, createTheme, ThemeProvider } from "@mui/material";
import { Row } from "../Row";

type Props = ComponentProps<typeof Button>;
export declare type LoadingButtonState = "available" | "loading";


export const LoadingButton = ({
  buttonState,
  ...props
}: Props & { buttonState: LoadingButtonState }) => {
  const loading = <ThemeProvider theme={createTheme({
    palette: {
      primary: {
        main: "#FFFFFF",
      },
      tertiary: {
        main: "#FFFFFF",
        light: "#FFFFFF",
        dark: "#FFFFFF",
        contrastText: "#FFFFFF",
      },
    }
  })}>
    <CircularProgress size={"24px"} color="primary" />
  </ThemeProvider>;

  return (
    <Button
      disabled={buttonState === "loading"}
      {...props}
      sx={{
        ...props.sx,
        width: "100%",
        height: "48px",
        fontWeight: "bold",
      }}
    >
      {buttonState === "loading" ?
        loading : props.children}
    </Button>
  );
};
