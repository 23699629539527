import { Card } from "../../components/common/elements/Card";
import { ResponsiveCol } from "../../components/common/ResponsiveCol";
import React, { FormEvent, useState } from "react";
import { ValidatedTextField } from "../../components/common/elements/ValidatedTextField";
import { Alert, Button, Typography } from "@mui/material";
import {
  LoadingButton,
  LoadingButtonState,
} from "../../components/common/elements/LoadingButton";
import { useTenantId } from "../../models/hooks/hooks";
import { AnonymousApiClient } from "../../models/apiClients/anonymousApiClient";
import { useNavigate } from "react-router-dom";
import { Col } from "../../components/common/Col";
import { Row } from "../../components/common/Row";

const SendComplete = () => {
  const navigate = useNavigate();
  return (
    <>
      <Col sx={{ marginBottom: "40px" }}>
        パスワード再設定メールを送信しました。
      </Col>
      <Button variant={"contained"} onClick={() => navigate("/")}>
        トップに戻る
      </Button>
    </>
  );
};

const ResetPasswordContent = (props: {
  setCompleted: (complete: boolean) => void;
}) => {
  const [email, setEmail] = useState<string>("");
  const [emailInputError, setEmailInputError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [buttonState, setButtonState] =
    useState<LoadingButtonState>("available");
  const tenantId = useTenantId();

  const hasError = (): boolean => {
    return emailInputError;
  };
  const onSubmit = async (e: FormEvent<any>) => {
    e.preventDefault();
    if (tenantId === undefined || tenantId === null) {
      return;
    }
    setErrorMessage("");
    setButtonState("loading");
    const client = AnonymousApiClient.getInstance();
    await client
      .sendPasswordResetLink(email, tenantId)
      .then(() => {
        props.setCompleted(true);
      })
      .catch((e) => {
        console.error(e);
        setErrorMessage("処理に失敗しました。時間をおいて再度お試しください。");
      });
    setButtonState("available");
  };
  return (
    <form onSubmit={onSubmit}>
      <ValidatedTextField
        type="email"
        label="メールアドレス"
        variant="outlined"
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
        inputProps={{
          required: true,
        }}
        watchError={setEmailInputError}
        sx={{
          backgroundColor: "white",
          borderRadius: "4px",
          width: "400px",
        }}
      />
      {!!errorMessage ? (
        <Alert severity="error" sx={{ marginTop: "16px" }}>
          {errorMessage}
        </Alert>
      ) : (
        <div></div>
      )}
      <LoadingButton
        type="submit"
        variant="contained"
        size={"large"}
        sx={{ alignSelf: "center", marginTop: "24px" }}
        disabled={hasError()}
        buttonState={buttonState}
      >
        メール送信
      </LoadingButton>
    </form>
  );
};
export const ResetPasswordScreen = () => {
  const [complete, setCompleted] = useState<boolean>(false);
  return (
    <ResponsiveCol
      className={"login-box"}
      sx={{
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Card
        withBorder
        filled
        sx={{
          padding: "40px",
          minWidth: "400px",
          alignItems: "center",
        }}
      >
        <Row sx={{ alignSelf: "start" }}>
          <Typography variant={"h1"} sx={{ marginBottom: "32px" }}>
            パスワード再設定メール送信
          </Typography>
        </Row>
        {complete ? (
          <SendComplete />
        ) : (
          <ResetPasswordContent setCompleted={setCompleted} />
        )}
      </Card>
    </ResponsiveCol>
  );
};
