import { Card } from "../../components/common/elements/Card";
import { ResponsiveCol } from "../../components/common/ResponsiveCol";
import React, { FormEvent, useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import { ValidatedTextField } from "../../components/common/elements/ValidatedTextField";
import { Alert, Button, Typography } from "@mui/material";
import {
  LoadingButton,
  LoadingButtonState,
} from "../../components/common/elements/LoadingButton";
import { Row } from "../../components/common/Row";
import {
  MAX_PASSWORD_LENGTH,
  MIN_PASSWORD_LENGTH,
  PASSWORD_PATTERN,
} from "@chatforce/common/src/constants/constants";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTenantId } from "../../models/hooks/hooks";
import { AnonymousApiClient } from "../../models/apiClients/anonymousApiClient";
import { Col } from "../../components/common/Col";
import { UnexpectedErrorScreen } from "../Error/UnexpectedErrorScreen";
import { LoadingScreen } from "../Loading/LoadingScreen";

const UpdateComplete = () => {
  const navigate = useNavigate();
  return (
    <>
      <Col sx={{ marginBottom: "40px" }}>パスワードの更新が完了しました。</Col>
      <Button variant={"contained"} onClick={() => navigate("/")}>
        トップに戻る
      </Button>
    </>
  );
};

const ResetPasswordUpdateContent = (props: {
  setCompleted: (completed: boolean) => void;
}) => {
  const [password, setPassword] = useState<string>("");
  const [passwordInputError, setPasswordInputError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isValid, setIsValid] = useState<boolean | undefined>(undefined);
  const [buttonState, setButtonState] =
    useState<LoadingButtonState>("available");
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const email = searchParams.get("email");
  const tenantId = useTenantId();
  console.log("parameters", tenantId, token, email);
  const hasError = (): boolean => {
    return passwordInputError;
  };
  useEffect(() => {
    if (
      tenantId === undefined ||
      tenantId === null ||
      email === null
    ) {
      return;
    }
    const client = AnonymousApiClient.getInstance();
    client.getPasswordResetStatus(tenantId, email).then((isValid) => {
      setIsValid(isValid);
    });
  }, [tenantId, email]);

  const onSubmit = async (e: FormEvent<any>) => {
    e.preventDefault();
    if (
      tenantId === undefined ||
      tenantId === null ||
      token === null ||
      email === null
    ) {
      return;
    }
    setErrorMessage("");
    setButtonState("loading");
    const auth = getAuth();
    auth.tenantId = tenantId;
    auth.useDeviceLanguage();
    const client = AnonymousApiClient.getInstance();
    await client
      .confirmPasswordReset(tenantId, email, token, password)
      .then(() => {
        props.setCompleted(true);
      })
      .catch((e) => {
        console.error(e);
        setErrorMessage("処理に失敗しました。時間をおいて再度お試しください。");
      });
    setButtonState("available");
    props.setCompleted(true);
  };
  if (
    tenantId === null ||
    email === null
  ) {
    console.log(tenantId, email);
    return <UnexpectedErrorScreen />;
  }
  if (tenantId === undefined || isValid === undefined) {
    return <LoadingScreen />;
  }
  if (!isValid) {
    return (
      <ResponsiveCol>
        <Card withBorder filled>
          パスワード再設定のURLが無効です。再度発行してください。
        </Card>
      </ResponsiveCol>
    );
  }
  return (
    <form onSubmit={onSubmit}>
      <ValidatedTextField
        type="password"
        label="パスワード"
        variant="outlined"
        value={password}
        onChange={(e) => {
          setPassword(e.target.value);
        }}
        inputProps={{
          required: true,
          minLength: MIN_PASSWORD_LENGTH,
          maxLength: MAX_PASSWORD_LENGTH,
          pattern: PASSWORD_PATTERN,
        }}
        watchError={setPasswordInputError}
        sx={{
          backgroundColor: "white",
          borderRadius: "4px",
          width: "400px",
        }}
      />
      <Row>
        パスワードは{MIN_PASSWORD_LENGTH}文字以上で、大文字・小文字・数字・記号を含む必要があります。
      </Row>
      {!!errorMessage ? (
        <Alert severity="error" sx={{ marginTop: "16px" }}>
          {errorMessage}
        </Alert>
      ) : (
        <div></div>
      )}
      <LoadingButton
        type="submit"
        variant="contained"
        size={"large"}
        sx={{ alignSelf: "center", marginTop: "24px" }}
        disabled={hasError()}
        buttonState={buttonState}
      >
        パスワード再設定
      </LoadingButton>
    </form>
  );
};
export const ResetPasswordUpdateScreen = () => {
  const [completed, setCompleted] = useState<boolean>(false);
  return (
    <ResponsiveCol
      className={"login-box"}
      sx={{
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Card
        withBorder
        filled
        sx={{
          padding: "40px",
          minWidth: "400px",
          alignItems: "center",
        }}
      >
        <Row sx={{ alignSelf: "start" }}>
          <Typography variant={"h1"} sx={{ marginBottom: "32px" }}>
            パスワード再設定
          </Typography>
        </Row>
        {completed ? (
          <UpdateComplete />
        ) : (
          <ResetPasswordUpdateContent setCompleted={setCompleted} />
        )}
      </Card>
    </ResponsiveCol>
  );
};
