import { Col } from "../Col";
import { ComponentProps } from "react";
import { Box } from "@mui/material";

type Props = ComponentProps<typeof Box>;
export function Card({
  filled,
  withBorder,
  ...props
}: Props & { filled?: boolean; withBorder?: boolean }) {
  return (
    <Col
      {...props}
      sx={{
        padding: "24px",
        backgroundColor: filled ? "#FFFFFF" : undefined,
        border: withBorder ? "1px solid #E0E0E0" : undefined,
        borderRadius: "8px",
        width: "auto",
        alignItems: "stretch",
        ...props.sx,
      }}
    >
      {props.children}
    </Col>
  );
}
