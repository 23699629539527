import {
  LoadingButton,
  LoadingButtonState,
} from "../../../components/common/elements/LoadingButton";
import React, { FormEvent, useEffect, useState } from "react";
import { StorageService } from "../../../models/services/storageService";
import { Alert, Box, Button, Snackbar } from "@mui/material";
import { useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from "recoil";
import { currentTenantUserQuery } from "../../../AppStates";
import { LoadingScreen } from "../../Loading/LoadingScreen";
import { Col } from "../../../components/common/Col";
import { TenantCustomClient } from "../../../models/apiClients/tenantCustomClient";

export const CustomPolicyUpload = () => {
  const tenantUserState = useRecoilValueLoadable(currentTenantUserQuery);
  const storageService = StorageService.getInstance();
  const tenantCustomClient = TenantCustomClient.getInstance();
  const inputRef = React.useRef<HTMLInputElement>(null);
  const refresher = useRecoilRefresher_UNSTABLE(currentTenantUserQuery);

  const [filename, setFilename] = useState<string | undefined>(undefined);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [fileUrl, setFileUrl] = useState<string | undefined>(undefined);
  const [src, setSrc] = useState<"remote" | "local" | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const [buttonState, setButtonState] =
    useState<LoadingButtonState>("available");
  const [openSaveSnackbar, setOpenSaveSnackbar] = useState<boolean>(false);

  useEffect(() => {
    tenantCustomClient.getTenantCustomPolicy().then((custom) => {
      const filename = custom?.policy?.filename;
      if (filename !== undefined) {
        setFilename(filename);
        setSrc("remote");
        setFileUrl(custom?.policy?.url);
      }
    });
  }, [tenantCustomClient]);
  if (tenantUserState.state === "loading") {
    return <LoadingScreen />;
  }
  const tenantId = tenantUserState.contents.tenantId;
  const onChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files[0]) {
      const firstFile = files[0];
      setFile(firstFile);
      setFilename(firstFile.name);
      setSrc("local");
    }
  };

  const onClickFile = () => {
    if (src === "local" && file !== undefined) {
      window.open(URL.createObjectURL(file), "_blank")?.focus();
    }
    if (src === "remote" && fileUrl !== undefined) {
      window.open(fileUrl, "_blank")?.focus();
    }
  };

  const isFileChanged = (): boolean => {
    if (src === "local") {
      // Localから新しいファイルが設定された場合
      return true;
    }
    if (src === "remote" && filename === undefined) {
      // Remoteから読み込んだファイルが削除された場合
      return true;
    }
    return false;
  };
  const onSubmit = async (e: FormEvent<any>) => {
    e.preventDefault();
    setButtonState("loading");
    setErrorMessage(undefined);
    setSrc(undefined);
    try {
      if (file === undefined) {
        await tenantCustomClient.deleteTenantCustomPolicy();
        await storageService.deleteFile(
          tenantId,
          `custom_policy/custom_policy.pdf`,
        );
      } else {
        await storageService.uploadFile(
          tenantId,
          `custom_policy/custom_policy.pdf`,
          file,
        );
        await tenantCustomClient.setTenantCustomPolicy(file.name);
      }
      refresher();
      setButtonState("available");
      setOpenSaveSnackbar(true);
    } catch (e) {
      console.error(e);
      setErrorMessage(
        "アップロードに失敗しました。時間をおいて再度お試しください。",
      );
      setButtonState("available");
    }
  };

  const onClickRemove = async () => {
    setButtonState("loading");
    setErrorMessage(undefined);
    try {
      setFile(undefined);
      setFilename(undefined);
      setButtonState("available");
    } catch (e) {
      console.error(e);
      setErrorMessage("削除に失敗しました。時間をおいて再度お試しください。");
      setButtonState("available");
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <Col>
        <Box
          sx={{
            marginBottom: "16px",
          }}
        >
          カスタム利用規約をアップロードすると、各ユーザーのログイン時に御社の利用規約の確認ページが表示されるようになります。
          <br />
          この規約に同意したユーザーのみがサービスを利用できるようになります。
          <br />
          新しい規約をアップロードすると、再度確認ページが表示されます。
        </Box>
        <Col
          sx={{
            padding: "16px",
            border: "2px dotted black",
            borderRadius: "16px",
            backgroundColor: "#E2E3E9",
            marginBottom: "32px",
          }}
        >
          <input
            type="file"
            accept="application/pdf"
            onChange={onChangeFile}
            onClick={(e: any) => {
              e.target.value = "";
            }}
            ref={inputRef}
            style={{ display: "none" }}
          />

          <Box
            sx={{
              marginBottom: "16px",
            }}
          >
            {filename === undefined ? (
              <Col>
                <Box sx={{ marginBottom: "16px" }}>
                  御社の利用規約PDFファイルを選択してください
                </Box>
                <Button
                  variant={"outlined"}
                  onClick={() => inputRef.current?.click()}
                  sx={{
                    padding: "8px",
                  }}
                >
                  ファイルを選択
                </Button>
              </Col>
            ) : (
              <Col>
                <Box
                  sx={{
                    borderRadius: "16px",
                    padding: "16px",
                    backgroundColor: "#FFFFFF",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  onClick={onClickFile}
                >
                  {filename}
                </Box>
                <Button
                  variant={"outlined"}
                  color={"error"}
                  onClick={onClickRemove}
                  sx={{
                    padding: "8px",
                    marginTop: "8px",
                  }}
                >
                  削除
                </Button>
              </Col>
            )}
          </Box>
        </Col>
        {!!errorMessage ? (
          <Alert severity={"error"} sx={{ marginBottom: "16px" }}>
            {errorMessage}
          </Alert>
        ) : (
          <div />
        )}
        <LoadingButton
          type="submit"
          buttonState={buttonState}
          disabled={!isFileChanged()}
          variant={"contained"}
          sx={{ width: "300px" }}
        >
          保存
        </LoadingButton>
        <Snackbar
          open={openSaveSnackbar}
          autoHideDuration={6000}
          onClose={() => {
            setOpenSaveSnackbar(false);
          }}
        >
          <Alert severity="success" variant={"filled"} sx={{ width: "100%" }}>
            設定変更を保存しました。
          </Alert>
        </Snackbar>
      </Col>
    </form >
  );
};
