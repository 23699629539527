import { Col } from "../../../components/common/Col";
import { Row } from "../../../components/common/Row";
import TextField from "@mui/material/TextField";
import {
  Alert,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  MenuItem,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import {
  IdTenantGroup,
  IdTenantUser,
  roleLabels,
} from "../../../entities/entities";
import {
  ServiceUserRole,
  TenantUserRole,
} from "@chatforce/common/src/dao/firestoreDao";
import { useRecoilValueLoadable } from "recoil";
import { currentTenantUserQuery } from "../../../AppStates";
import { AdminApiClient } from "../../../models/apiClients/adminApiClient";
import { getAnalytics, logEvent } from "firebase/analytics";
import { MAX_USER_NAME_LENGTH } from "@chatforce/common/src/constants/constants";
import { ValidatedTextField } from "../../../components/common/elements/ValidatedTextField";

const rowStyle = {
  paddingBottom: "10px",
  alignItems: "center",
};
const labelStyle = {
  minWidth: "120px",
  marginRight: "16px",
  fontWeight: "bold",
};

const formStyle = {
  maxWidth: "240px",
  minWidth: "240px",
  "& > *": {
    width: "100%",
  },
};
export const EditTenantUserDialog = (props: {
  tenantId: string;
  tenantUser: IdTenantUser | null;
  groups: IdTenantGroup[];
  onClose: () => void;
}) => {
  const loginUser = useRecoilValueLoadable(currentTenantUserQuery);
  const [displayName, setDisplayName] = useState<string>("");
  const [displayNameInputError, setDisplayNameInputError] =
    useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [group, setGroup] = useState<string>("");
  const [role, setRole] = useState<TenantUserRole>("none");
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const analytics = getAnalytics();

  const hasError = (): boolean => {
    return displayNameInputError;
  };

  useEffect(() => {
    if (props.tenantUser === null) return;
    console.log(props);
    setDisplayName(props.tenantUser.userDisplayName ?? "");
    setEmail(props.tenantUser.userEmail ?? "");
    setGroup(props.tenantUser.groupId ?? "");
    setRole((props.tenantUser.role as TenantUserRole) ?? "");
  }, [props.tenantUser]);
  const onChangeGroup = (event: ChangeEvent<{ value: unknown }>) => {
    setGroup(event.target.value as string);
  };

  const onChangeRole = (event: ChangeEvent<{ value: unknown }>) => {
    setRole(event.target.value as TenantUserRole);
  };

  const handleOnClose = () => {
    setDisplayName("");
    setGroup("");
    setRole("none");
    setErrorMessage(undefined);
    props.onClose();
  };

  const onClickSave = async () => {
    if (!props.tenantUser) {
      return;
    }
    setErrorMessage(undefined);
    try {
      const adminClient = AdminApiClient.getInstance();
      const groupOrUndefined: string | undefined = group === "" ? undefined : group;
      if (props.tenantUser.status === "invited") {
        await adminClient.updateInvitation(
          props.tenantUser?.uid,
          displayName,
          groupOrUndefined,
          role,
        );
      } else if (props.tenantUser.status === "registered") {
        await adminClient.updateTenantUser(
          props.tenantUser?.uid,
          displayName,
          groupOrUndefined,
          role,
        );
      }
      handleOnClose();
      logEvent(analytics, "saved_tenant_user");
    } catch (e) {
      console.error(e);
      setErrorMessage("保存に失敗しました。時間をおいて再度お試しください。");
    }
  };
  if (loginUser.state !== "hasValue") {
    return <></>;
  }
  let roleOptions: ServiceUserRole[] = [];
  const userRole = loginUser.getValue()?.role;
  if (userRole === "admin") {
    roleOptions = ["admin", "user"];
  }
  if (userRole === "superAdmin" || userRole === "serviceAdmin") {
    roleOptions = ["superAdmin", "admin", "user"];
  }

  let groups = props.groups.map((group) => {
    return (
      <MenuItem value={group.id} sx={formStyle} key={group.id}>
        {group.displayName}
      </MenuItem>
    );
  });
  groups.unshift(
    <MenuItem value={"未設定"} sx={formStyle} key={"unset"}>
      {"未設定"}
    </MenuItem>,
  );
  return (
    <Dialog
      open={props.tenantUser !== null}
      onClose={() => {
        handleOnClose();
      }}
    >
      <DialogTitle>ユーザー詳細</DialogTitle>
      <Divider
        sx={{
          marginLeft: "5%",
          marginRight: "5%",
        }}
      />
      <Col
        sx={{
          alignItems: "start",
          padding: "40px",
        }}
      >
        <Row sx={rowStyle}>
          <Row sx={labelStyle}>ユーザー名</Row>
          <Row sx={formStyle}>
            <ValidatedTextField
              type={"text"}
              value={displayName}
              inputProps={{ maxLength: MAX_USER_NAME_LENGTH }}
              onChange={(event) => {
                setDisplayName(event.target.value);
              }}
              watchError={setDisplayNameInputError}
              sx={{
                width: "300px",
              }}
            />
          </Row>
        </Row>
        <Row sx={rowStyle}>
          <Row sx={labelStyle}>メールアドレス</Row>
          <Row sx={formStyle}>{email ?? ""}</Row>
        </Row>
        <Row sx={rowStyle}>
          <Row sx={labelStyle}>グループ</Row>
          <Row sx={formStyle}>
            <TextField
              select
              id="group-select"
              value={group}
              onChange={onChangeGroup}
            >
              {
                groups
              }
            </TextField>
          </Row>
        </Row>
        <Row sx={rowStyle}>
          <Row sx={labelStyle}>権限</Row>
          <Row sx={formStyle}>
            <TextField
              select
              id="role-select"
              value={role}
              onChange={onChangeRole}
            >
              {roleOptions.map((r) => {
                return (
                  <MenuItem value={r} sx={formStyle} key={r}>
                    {roleLabels[r as TenantUserRole] ?? ""}
                  </MenuItem>
                );
              })}
            </TextField>
          </Row>
        </Row>
        {!!errorMessage ? (
          <Alert severity={"error"} sx={{ marginTop: "16px" }}>
            {errorMessage}
          </Alert>
        ) : (
          <div />
        )}
        <Row sx={{ marginTop: "20px" }}>
          <Button
            onClick={handleOnClose}
            variant={"outlined"}
            sx={{ marginRight: "20px" }}
          >
            キャンセル
          </Button>
          <Button
            onClick={onClickSave}
            variant={"contained"}
            disabled={hasError()}
          >
            変更
          </Button>
        </Row>
      </Col>
    </Dialog>
  );
};
