import { Box } from "@mui/material";
import { ComponentProps } from "react";

type Props = ComponentProps<typeof Box>;
export function Col(props: Props) {
  return (
    <Box
      {...props}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        alignSelf: "stretch",
        ...props.sx,
      }}
    >
      {props.children}
    </Box>
  );
}
