import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  IconButtonProps,
  List,
  ListItem,
  ListSubheader,
  styled,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Row } from "../../../components/common/Row";
import { Col } from "../../../components/common/Col";
import { DateTime } from "luxon";
import { presetTemplateCategories, presetTemplates } from "./PresetTemplates";
import { PromptTemplate } from "@chatforce/common/src/dao/firestoreDao";
import { PromptTemplateCategory } from "@chatforce/common/src/dao/firestoreDao";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TENANT_PROMPT } from "../../../utils/constants";
import { UserApiClient } from "../../../models/apiClients/userApiClient";
import { TemplateDialog } from "./TemplateDialog";

// Source: https://mui.com/material-ui/react-card/#system-RecipeReviewCard.tsx
interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));
const TemplateCell = (props: {
  template: PromptTemplate;
  setPrompt: (prompt: string) => void;
}) => {
  const [expanded, setExpanded] = useState(false);
  const createdAtString = DateTime.fromJSDate(
    props.template.createdAt,
  ).toFormat("yyyy/MM/dd");
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  return (
    <Col
      sx={{
        width: "100%",
        paddingTop: "16px",
        paddingLeft: "32px",
        paddingRight: "32px",
      }}
    >
      <Row sx={{ alignItems: "start", marginBottom: "8px", width: "100%" }}>
        <Col sx={{ maxWidth: "80%" }}>
          <Typography
            variant={"h5"}
            sx={{
              marginBottom: "4px",
              whiteSpace: expanded ? "inherit" : "nowrap",
              overflow: expanded ? "inherit" : "hidden",
              textOverflow: expanded ? "inherit" : "ellipsis",
              maxWidth: "100%",
            }}
          >
            {props.template.title}
          </Typography>
          <Box sx={{ display: "flex", gap: "16px" }}>
            {props.template.ownerName && (
              <Typography sx={{ fontSize: "12px", color: "#78787D" }}>
                作成者：{props.template.ownerName}
              </Typography>
            )}
            <Typography sx={{ fontSize: "12px", color: "#78787D" }}>
              作成日：{createdAtString}
            </Typography>
          </Box>
        </Col>
        <Box sx={{ flex: 1 }} />
        <Button
          variant={"contained"}
          onClick={() => {
            props.setPrompt(props.template.prompt);
          }}
        >
          使用
        </Button>
      </Row>
      <Row
        sx={{
          maxWidth: "100%",
          minWidth: 0,
          overflow: "hidden",
        }}
      >
        <Typography
          variant={"body2"}
          sx={{
            whiteSpace: expanded ? "inherit" : "nowrap",
            overflow: expanded ? "inherit" : "hidden",
            textOverflow: expanded ? "inherit" : "ellipsis",
            alignSelf: "center",
            maxWidth: "80%",
          }}
        >
          {props.template.prompt}
        </Typography>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
          sx={{ justifySelf: "end" }}
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </Row>
      <Divider
        sx={{
          paddingTop: "16px",
          width: "100%",
        }}
      />
    </Col>
  );
};

const Categories = (props: {
  categories: PromptTemplateCategory[];
  setSelectedCategory: (category: PromptTemplateCategory) => void;
  selectedCategory: PromptTemplateCategory;
}) => {
  const customPreset = props.categories[0];
  const mainPresets = props.categories.slice(1, props.categories.length);
  const customSelected = props.selectedCategory === customPreset;

  return (
    <List sx={{ width: "100%", marginLeft: "8px", marginRight: "8px" }}>
      <ListItem
        key={customPreset.id}
        sx={{
          height: "28px",
          paddingLeft: "16px",
          width: "100%",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          borderRadius: "4px",
          backgroundColor: customSelected ? "#C7C8CD" : "",
          cursor: "pointer",
          "&:hover": { backgroundColor: customSelected ? "" : "#E2E3E9" },
        }}
        onClick={() => {
          props.setSelectedCategory(customPreset);
        }}
      >
        <Typography variant={"body2"} sx={{ fontWeight: "bold" }}>
          社内共有
        </Typography>
      </ListItem>
      <ListSubheader component="div" id="nested-list-subheader">
        プリセット
      </ListSubheader>
      {mainPresets.map((category) => {
        const selected = props.selectedCategory === category;
        return (
          <ListItem
            key={category.id}
            sx={{
              height: "28px",
              paddingLeft: "16px",
              width: "100%",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              borderRadius: "4px",
              backgroundColor: selected ? "#C7C8CD" : "",
              cursor: "pointer",
              "&:hover": { backgroundColor: selected ? "" : "#E2E3E9" },
            }}
            onClick={() => {
              props.setSelectedCategory(category);
            }}
          >
            <Typography variant={"body2"} sx={{ fontWeight: "bold" }}>
              {category.title}
            </Typography>
          </ListItem>
        );
      })}
    </List>
  );
};

export const PromptLibraryDialog = (props: {
  isOpen: boolean;
  onClose: () => void;
  setPrompt: (prompt: string) => void;
}) => {
  const userApiClient = UserApiClient.getInstance();
  const categories = presetTemplateCategories;
  const templates = presetTemplates;
  const [selectedCategory, setSelectedCategory] =
    useState<PromptTemplateCategory>(categories[1]);
  const [customTemplates, setCustomTemplates] = useState<PromptTemplate[]>([]);
  const [openTemplateInput, setOpenTemplateInput] = useState(false);
  const customPresetSelected = selectedCategory.id === TENANT_PROMPT;

  useEffect(() => {
    if (customPresetSelected && customTemplates.length === 0) fetchTemplates();
  }, [selectedCategory]);

  const fetchTemplates = async () => {
    const res = await userApiClient.listTemplates();
    const tmpTemplates: PromptTemplate[] = [];
    res.templates.forEach((template) => {
      const createdAt = template.createdAt ? template.createdAt.toDate() : "";

      tmpTemplates.push({
        id: template.id,
        title: template.title,
        prompt: template.prompt,
        ownerId: template.ownerId,
        ownerName: template.ownerName,
        createdAt,
        updatedAt: createdAt,
        categoryId: TENANT_PROMPT,
        deletedAt: "",
        language: "ja",
        shareScope: "tenant",
        source: "",
      });
    });
    setCustomTemplates(tmpTemplates);
  };

  const handleOnClose = () => {
    props.onClose();
  };

  const handleTemplateSave = () => fetchTemplates();

  const PresetTemplates = () => {
    let categoryTemplates: PromptTemplate[] = [];

    if (customPresetSelected) categoryTemplates = customTemplates;
    if (!customPresetSelected)
      categoryTemplates = templates.filter(
        (template) => template.categoryId === selectedCategory.id,
      );

    return (
      <Col>
        {categoryTemplates.map((template) => (
          <TemplateCell
            template={template}
            setPrompt={(prompt) => {
              props.setPrompt(prompt);
              props.onClose();
            }}
            key={template.id}
          />
        ))}
      </Col>
    );
  };

  return (
    <>
      <Dialog
        PaperProps={{
          sx: {
            minHeight: "90vh",
            maxHeight: "90vh",
          },
        }}
        open={props.isOpen}
        maxWidth="xl"
        scroll="paper"
        fullWidth
        onClose={() => {
          handleOnClose();
        }}
      >
        <DialogTitle typography={"h2"}>プロンプトライブラリ</DialogTitle>
        <Row sx={{ width: "100%", typography: "body1", overflow: "auto" }}>
          <Col sx={{ minWidth: "180px" }}>
            <Categories
              categories={categories}
              setSelectedCategory={(category) => {
                setSelectedCategory(category);
              }}
              selectedCategory={selectedCategory}
            />
          </Col>
          <Col
            sx={{
              overflowX: "inherit",
              overflowY: "auto",
              flex: 1,
            }}
          >
            <PresetTemplates />
          </Col>
        </Row>
        {customPresetSelected && (
          <>
            <Box sx={{ flex: 1 }} />
            <Button
              variant={"contained"}
              sx={{
                margin: "16px",
                width: "fit-content",
                alignSelf: "flex-end",
              }}
              onClick={() => setOpenTemplateInput(true)}
            >
              社内テンプレートを追加
            </Button>
          </>
        )}
      </Dialog>
      <TemplateDialog
        open={openTemplateInput}
        onSaved={handleTemplateSave}
        onClose={() => setOpenTemplateInput(false)}
      />
    </>
  );
};
