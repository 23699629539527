import { Col } from "../../../components/common/Col";
import { Row } from "../../../components/common/Row";
import { Alert, Button, Dialog, DialogTitle, Divider } from "@mui/material";
import { useState } from "react";
import { IdTenantGroup } from "../../../entities/entities";
import { AdminApiClient } from "../../../models/apiClients/adminApiClient";
import { getAnalytics, logEvent } from "firebase/analytics";
import { MAX_GROUP_NAME_LENGTH } from "@chatforce/common/src/constants/constants";
import { ValidatedTextField } from "../../../components/common/elements/ValidatedTextField";

const rowStyle = {
  paddingBottom: "10px",
  alignItems: "center",
};
const labelStyle = {
  minWidth: "120px",
  marginRight: "16px",
  fontWeight: "bold",
};

const formStyle = {
  maxWidth: "240px",
  minWidth: "240px",
  "& > *": {
    width: "100%",
  },
};
export const AddGroupDialog = (props: {
  groups: IdTenantGroup[];
  open: boolean;
  onSaved: () => void;
  onClose: () => void;
}) => {
  const [displayName, setDisplayName] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const [displayNameInputError, setDisplayNameInputError] =
    useState<boolean>(false);
  const analytics = getAnalytics();

  const hasError = (): boolean => {
    return displayNameInputError;
  };
  const handleOnClose = () => {
    console.log("handleOnClose");
    setDisplayName("");
    setErrorMessage(undefined);
    props.onClose();
  };
  const onClickAdd = async () => {
    setErrorMessage(undefined);
    const adminApiClient = AdminApiClient.getInstance();
    await adminApiClient
      .addTenantGroup(displayName)
      .then(() => {
        console.log("add succeeded");
        props.onSaved();
        handleOnClose();
        logEvent(analytics, "added_tenant_user");
      })
      .catch((err: unknown) => {
        setErrorMessage("処理に失敗しました。時間をおいて再度試してください。");
        console.error(err);
      });
  };

  return (
    <Dialog
      open={props.open}
      onClose={() => {
        handleOnClose();
      }}
    >
      <DialogTitle>グループ追加</DialogTitle>
      <Divider
        sx={{
          marginLeft: "5%",
          marginRight: "5%",
        }}
      />
      <Col
        sx={{
          alignItems: "start",
          padding: "24px",
        }}
      >
        <Row sx={rowStyle}>
          <Row sx={labelStyle}>グループ名</Row>
          <Row sx={formStyle}>
            <ValidatedTextField
              type={"text"}
              value={displayName}
              inputProps={{ maxLength: MAX_GROUP_NAME_LENGTH }}
              onChange={(event) => {
                setDisplayName(event.target.value);
              }}
              watchError={setDisplayNameInputError}
              sx={{
                width: "300px",
              }}
            />
          </Row>
        </Row>
        {errorMessage !== undefined && (
          <Alert severity={"error"} sx={{ marginTop: "16px" }}>
            {errorMessage}
          </Alert>
        )}
        <Row sx={{ marginTop: "20px" }}>
          <Button
            onClick={handleOnClose}
            variant={"outlined"}
            sx={{ marginRight: "20px" }}
          >
            キャンセル
          </Button>
          <Button
            onClick={onClickAdd}
            variant={"contained"}
            disabled={hasError()}
          >
            保存
          </Button>
        </Row>
      </Col>
    </Dialog>
  );
};
