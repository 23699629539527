import {
  AllowedIpAddressDao,
  ServiceUserRole,
  TenantDao,
  TenantGroupDao,
  TenantUserDao,
} from "@chatforce/common/src/dao/firestoreDao";

export const roleLabels: { [key in ServiceUserRole]: string } = {
  user: "ユーザー",
  admin: "管理者",
  superAdmin: "特権管理者",
  serviceAdmin: "サービス管理者",
  none: "なし",
};

export interface AuthState {
  tenantId: string;
  uid: string;
}

export interface IdTenant extends TenantDao {
  id: string;
}

export interface IdTenantUser extends TenantUserDao {
  uid: string;
  tenantId: string;
}
export interface IdTenantGroup extends TenantGroupDao {
  id: string;
}

export interface CustomClaim {
  role?: ServiceUserRole;
  customPolicyAgreedAt?: number;
}

// userId is key, value is user image url
export type UsersImgRecord = Record<string, string | null>;

export interface IdAllowedIpAddressDao extends AllowedIpAddressDao {
  id: string;
}
