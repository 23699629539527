import { Col } from "../../components/common/Col";
import { Box } from "@mui/material";
import React from "react";

export const NotFoundScreen = () => {
  return (
    <Col
      sx={{
        flex: 1,
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        height: "400px",
      }}
    >
      <Box>お探しのページを見つけることができませんでした。</Box>
    </Col>
  );
};
