import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ResponsiveCol } from "../../../components/common/ResponsiveCol";
import { Label } from "../../../components/common/Label";

import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { AdminApiClient } from "../../../models/apiClients/adminApiClient";
import {
  AuditLog,
  ListAuditLogsResponse,
} from "../../../buf/chatforce/admin/v1/admin_pb";
import { useCustomPagination } from "../../../models/hooks/hooks";
import { getLastMonths } from "../../../utils/dateUtils";

const tableCellStyle = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

export const AuditLogs = () => {
  const adminClient = AdminApiClient.getInstance();
  const lastTwelveMonths = getLastMonths(12);

  const [auditLogs, setAuditLogs] = useState<AuditLog[]>([]);
  const [selectedMonth, setSelectedMonth] = useState(lastTwelveMonths[0]);
  const { page, pageCount, paginatedData, showPagination, handlePageChange } =
    useCustomPagination({ items: auditLogs });

  const handleChangeMonth = (value: string) => {
    const currentMonthObj = lastTwelveMonths.find(
      (item) => item.yearMonthStr === value,
    );
    if (currentMonthObj) setSelectedMonth(currentMonthObj);
  };

  useEffect(() => {
    const startDate = new Date(selectedMonth.date);
    startDate.setDate(1);
    startDate.setHours(0, 0, 0, 0);

    const endDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth() + 1,
      1,
    );
    endDate.setDate(0);
    endDate.setHours(23, 59, 59, 999);

    adminClient
      .listAuditLogs({
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      })
      .then((res: ListAuditLogsResponse) => {
        setAuditLogs(res.auditLogs);
      });
  }, [selectedMonth]);

  return (
    <ResponsiveCol sx={{ height: "100%" }}>
      <Box sx={{ flex: 1, width: "100%" }}>
        <Typography variant={"h1"} sx={{ marginBottom: "40px" }}>
          監査ログ
        </Typography>

        <Label>ログリスト</Label>
        <FormControl sx={{ width: 140, marginTop: "16px" }}>
          <InputLabel id="select-month">年月を選択</InputLabel>
          <Select
            labelId="select-month"
            value={selectedMonth.yearMonthStr}
            label="月を選択"
            onChange={(event) => handleChangeMonth(event.target.value)}
          >
            {lastTwelveMonths.map((item) => (
              <MenuItem value={item.yearMonthStr} key={item.yearMonthStr}>
                {item.yearMonthStr}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TableContainer
          component={Paper}
          sx={{
            display: "flex",
            width: "100%",
            maxHeight: "calc(100vh - 450px)",
            marginTop: "16px",
          }}
        >
          <Table sx={{ minWidth: "100%", width: "100%" }} size="small">
            <TableHead
              sx={{
                backgroundColor: "white",
                position: "sticky",
                zIndex: 1,
                top: "0",
              }}
            >
              <TableRow
                sx={{
                  backgroundColor: "#fff",
                }}
              >
                {/* TODO: Please check japanese translation and Order of column.
                uid: ユーザーID 
                timestamp: タイムスタンプ 
                email: メールアドレス
                action: アクション 
                ipAddress: IPアドレス 
                displayName: 表示名 */}
                <TableCell sx={{ ...tableCellStyle }}>ユーザーID</TableCell>
                <TableCell sx={{ ...tableCellStyle }}>タイムスタンプ</TableCell>
                <TableCell sx={{ ...tableCellStyle }}>メールアドレス</TableCell>
                <TableCell sx={{ ...tableCellStyle }}>アクション</TableCell>
                <TableCell sx={{ ...tableCellStyle }}>IPアドレス</TableCell>
                <TableCell sx={{ ...tableCellStyle }}>表示名</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData.map((auditLog) => {
                return (
                  <TableRow key={auditLog.id}>
                    <TableCell
                      sx={{ ...tableCellStyle }}
                      component="th"
                      scope="row"
                    >
                      {auditLog.uid}
                    </TableCell>
                    <TableCell sx={{ ...tableCellStyle }}>
                      {auditLog.timestamp
                        ? DateTime.fromJSDate(
                          auditLog.timestamp.toDate(),
                        ).toISO({
                          suppressMilliseconds: true,
                        })
                        : ""}
                    </TableCell>
                    <TableCell
                      sx={{
                        ...tableCellStyle,
                        maxWidth: "150px",
                      }}
                    >
                      {auditLog.email}
                    </TableCell>
                    <TableCell
                      sx={{
                        ...tableCellStyle,
                        maxWidth: "150px",
                      }}
                    >
                      {auditLog.action}
                    </TableCell>
                    <TableCell
                      sx={{
                        ...tableCellStyle,
                        maxWidth: "150px",
                      }}
                    >
                      {auditLog.ipAddress}
                    </TableCell>
                    <TableCell
                      sx={{
                        ...tableCellStyle,
                        maxWidth: "150px",
                      }}
                    >
                      {auditLog.displayName}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {showPagination && (
          <Stack alignItems="flex-end" marginTop={1}>
            <Pagination
              count={pageCount}
              page={page}
              onChange={(_, value) => handlePageChange(value)}
            />
          </Stack>
        )}
      </Box>
    </ResponsiveCol>
  );
};
