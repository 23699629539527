import { Col } from "../common/Col";
import { Box, Link } from "@mui/material";
import { Outlet } from "react-router-dom";
import React, { ComponentProps } from "react";
import { Row } from "../common/Row";
import { ResponsiveCol } from "../common/ResponsiveCol";
import { Logo } from "../menu/Logo";

type Props = ComponentProps<typeof Link>;

const LinkText = (props: Props) => {
  return (
    <Link
      {...props}
      underline="none"
      sx={{
        fontSize: "small",
        color: "#555555",
        padding: "1px",
      }}
    >
      {props.children}
    </Link>
  );
};
const Footer = () => {
  return (
    <Row
      sx={{
        marginTop: "auto",
        width: "100%",
        height: "100%",
      }}
    >
      <ResponsiveCol sx={{ justifyContent: "end" }}>
        <LinkText href={"terms"}>利用規約</LinkText>
        <LinkText href={"privacy"}>プライバシーポリシー</LinkText>
        <LinkText href={"cookies"}>Cookieについて</LinkText>
        <LinkText href={"transactions_law"}>特定商取引法に基づく表記</LinkText>
        <LinkText href={"about"}>ChatForceについて</LinkText>
      </ResponsiveCol>
    </Row>
  );
};
export const BaseLayout = () => {
  return (
    <Col
      sx={{
        height: "100vh",
        justifyContent: "start",
        alignItems: "stretch",
      }}
    >
      <Box sx={{ marginTop: "24px", marginLeft: "24px", marginBottom: "14px" }}>
        <Logo />
      </Box>
      <Row
        sx={{
          width: "100%",
          justifyContent: "center",
        }}
      >
        <Outlet />
      </Row>
      <Footer />
    </Col>
  );
};
