import { useRecoilValue } from "recoil";
import { currentLoginTenantQuery } from "../../../AppStates";
import React, { useEffect, useState } from "react";
import * as ipaddr from 'ipaddr.js';
import {
  IdAllowedIpAddressDao,
} from "../../../entities/entities";
import { Row } from "../../../components/common/Row";
import {
  Alert,
  Box,
  Button,
  FormControlLabel,
  Paper,
  Snackbar,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { AdminApiClient } from "../../../models/apiClients/adminApiClient";
import { LoadingScreen } from "../../Loading/LoadingScreen";
import { red } from "@mui/material/colors";
import { DateTime } from "luxon";
import { Label } from "../../../components/common/Label";
import { ConfirmationDialog } from "../../../components/common/elements/ConfirmationDialog";
import { AllowedIp } from "../../../buf/chatforce/admin/v1/admin_pb";

const tableCellStyle = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: "240px",
};

const validateIpFormat = (ip: string): boolean => {
  // ipaddr.isValidだと"1"などの極端な値も許可してしまうため、isValidFourPartDecimalとisValidCIDRを使う
  const isValidV4 = ipaddr.IPv4.isValidFourPartDecimal(ip) || ipaddr.isValidCIDR(ip);
  const isValidV6 = ipaddr.IPv6.isValid(ip);
  return isValidV4 || isValidV6;
}

export const IpProtectionTable = (props: {}) => {
  const defaultErrorMessage = "許可IPアドレスの更新に失敗しました。";
  const [enabledIpBlock, setEnabledIpBlock] = useState<boolean | null>(null);
  const [allowedIps, setAllowedIps] = useState<IdAllowedIpAddressDao[]>([]);

  const loginTenantState = useRecoilValue(currentLoginTenantQuery);
  const tenantId = loginTenantState?.id;

  const [openIpBlockSwitching, setOpenIpBlockSwitching] =
    useState<boolean>(false);
  const [inputIp, setInputIp] = useState<string>("");
  const [inputIpId, setInputIpId] = useState<string | undefined>(
    undefined,
  );
  const [refresh, setRefresh] = useState<boolean>(true);
  const [deletingIpId, setDeletingIpId] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const adminApiClient = AdminApiClient.getInstance();
  const showAddButton = allowedIps.length < 10 || inputIpId;

  useEffect(() => {
    if (tenantId === undefined) return;
    if (refresh === false) return;
    adminApiClient.listAllowedIps().then((res) => {
      const tmpAllowedIps = res.allowedIps.map((item: AllowedIp) => ({
        id: item.id,
        ip: item.ip,
        createdBy: item.createdBy,
        createdAt: item.createdAt,
      }));

      setAllowedIps(tmpAllowedIps);
    });
    adminApiClient.getTenantIpBlock().then((res) => {
      setEnabledIpBlock(res.enabledIpBlock);
    });
    setRefresh(false);
  }, [adminApiClient, refresh]);



  if (tenantId === undefined) {
    return <LoadingScreen />;
  }

  const onClickDeleteAllowedIp = async (id: string) => {
    setDeletingIpId(id);
  };

  const onClickSaveIp = async () => {
    if (inputIp.trim() === "") return;
    if (!validateIpFormat(inputIp)) {
      setErrorMessage("IPアドレスの形式が正しくありません");
      return;
    }
    try {
      await adminApiClient.upsertAllowedIp(inputIp, inputIpId);
      clear();
      setRefresh(true);
    } catch (err) {
      console.error(err);
      setErrorMessage(defaultErrorMessage);
    }
  };

  const deleteAllowedIp = async (id: string | null) => {
    if (id === null) return;
    try {
      const { currentIp, updated } = await adminApiClient.deleteAllowedIp(id);
      if (updated === false) {
        let currentIpStr = "";
        if (currentIp !== null && currentIp !== undefined) {
          currentIpStr = `（${currentIp}）`;
        }
        const message = `現在のIPアドレス${currentIpStr}がブロックされるため削除できません。許可IP機能をオフにしてから再度お試しください。`;
        setErrorMessage(message);
        return;
      }
      console.log(`currentIp: ${currentIp}`);
      clear();
      setRefresh(true);
    } catch (err) {
      console.error(err);
      setErrorMessage(defaultErrorMessage);
    }
  };

  const switchIpBlock = async () => {
    try {
      const { currentIp, updated } = await adminApiClient.updateTenantIpBlock(!enabledIpBlock);
      console.log(`currentIp: ${currentIp}`);
      setOpenIpBlockSwitching(false);
      if (updated === false) {
        let currentIpStr = "";
        if (currentIp !== null && currentIp !== undefined) {
          currentIpStr = `（${currentIp}）`;
        }
        const message = `現在のIPアドレス${currentIpStr}がブロックされるため有効化できません。`;
        setErrorMessage(message);
        return;
      }
      setRefresh(true);
    } catch (err) {
      console.error(err);
      setErrorMessage(defaultErrorMessage);
    }
  };

  const openIpBlockSwitchingDialog = () => {
    setOpenIpBlockSwitching(true);
  };

  const changeIpInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputIp(event.target.value);
  };

  const clear = () => {
    setInputIp("");
    setInputIpId(undefined);
  };

  return (
    <Box
      sx={{
        width: "100%",
        alignItems: "start",
      }}
    >
      <Typography variant={"body1"}>
        サービスへのアクセスを許可するIPアドレスを設定します
      </Typography>
      <Typography
        variant={"body2"}
        sx={{ marginBottom: "40px", color: red[500] }}
      >
        お客様の現在使用しているIPアドレスが含まれていない場合、こちらの画面にもログインできなくなります。その場合はお問い合わせからご連絡ください
      </Typography>

      {enabledIpBlock !== null && (
        <FormControlLabel
          sx={{ marginBottom: "20px" }}
          control={
            <Switch
              checked={enabledIpBlock}
              onChange={openIpBlockSwitchingDialog}
            />
          }
          label={enabledIpBlock ? "有効" : "無効"}
        />
      )}
      <TableContainer component={Paper} sx={{ display: "flex", width: "100%" }}>
        <Box sx={{ display: "flex", width: "100%" }}>
          <Table sx={{ width: "100%" }} size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ ...tableCellStyle, maxWidth: "100px" }}>
                  許可IPアドレス
                </TableCell>
                <TableCell sx={{ ...tableCellStyle, maxWidth: "100px" }}>
                  作成日
                </TableCell>
                <TableCell sx={{ ...tableCellStyle, maxWidth: "100px" }}>
                  登録者 ID
                </TableCell>
                <TableCell sx={{ width: "100px" }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allowedIps.map((ipInfo) => {
                return (
                  <TableRow key={ipInfo.id} sx={{ height: "50px" }}>
                    <TableCell sx={tableCellStyle}>{ipInfo.ip}</TableCell>
                    <TableCell sx={tableCellStyle}>
                      {ipInfo.createdAt
                        ? DateTime.fromJSDate(
                          ipInfo.createdAt.toDate(),
                        ).toFormat("yyyy年MM月dd日")
                        : ""}
                    </TableCell>
                    <TableCell sx={tableCellStyle}>
                      {ipInfo.createdBy}
                    </TableCell>
                    <TableCell>
                      <Button
                        color="error"
                        variant={"contained"}
                        onClick={() => {
                          onClickDeleteAllowedIp(ipInfo.id);
                        }}
                      >
                        削除
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </TableContainer>
      {showAddButton && (
        <>
          <Row sx={{ marginTop: "20px" }}>
            <Label>IPアドレス</Label>
          </Row>
          <Row sx={{ gap: "16px" }}>
            <TextField value={inputIp} onChange={changeIpInput}></TextField>
            <Button onClick={onClickSaveIp} variant={"contained"}>
              追加
            </Button>
          </Row>
        </>
      )}
      <ConfirmationDialog
        open={deletingIpId !== null}
        title={"許可IPアドレスを削除する"}
        body={"削除しますか？"}
        buttonColor={"error"}
        buttonText={"削除"}
        onConfirm={async () => {
          await deleteAllowedIp(deletingIpId);
          setDeletingIpId(null);
        }}
        onClose={() => {
          setDeletingIpId(null);
        }}
      />
      <ConfirmationDialog
        open={openIpBlockSwitching}
        title={"IPアドレス制限の変更"}
        body={`${enabledIpBlock ? "IPアドレスによる制限を停止し" : "IPアドレスによる制限を開始し"}てもよいですか？`}
        buttonColor={"primary"}
        buttonText={`${enabledIpBlock ? "ブロックを停止" : "ブロックを開始"}`}
        onConfirm={async () => {
          await switchIpBlock();
          setOpenIpBlockSwitching(false);
        }}
        onClose={() => {
          setOpenIpBlockSwitching(false);
        }}
      />
      <Snackbar
        open={errorMessage !== null}
        autoHideDuration={6000}
        onClose={() => setErrorMessage(null)}
      >
        <Alert severity="error" variant={"filled"} sx={{ width: "100%" }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};
