import { Button, Typography } from "@mui/material"
import { ResponsiveCol } from "./ResponsiveCol"
import { Row } from "./Row"
import { useNavigate } from "react-router";
import { Card } from "./elements/Card";

export interface RedirectScreenProps {
    header: string,
    message: string,
    buttonText: string,
    path: string,
}

export const RedirectScreen = (props: RedirectScreenProps) => {
    const navigate = useNavigate();
    const onClick = () => {
        navigate(props.path, { replace: true });
    }
    return <ResponsiveCol sx={{ width: "100%" }}>
        <Card
            filled
            withBorder
            sx={{
                padding: "40px",
                width: "100%",
                alignItems: "center",
                alignSelf: "center",
            }}
        >
            <Typography variant="h1">{props.header}</Typography>
            <Row sx={{
                paddingTop: "40px",
                paddingBottom: "40px",
            }}>{props.message}</Row>
            <Button onClick={onClick} variant="contained" size="large">
                {props.buttonText}
            </Button>
        </Card>
    </ResponsiveCol >
}