import { ResponsiveCol } from "../../components/common/ResponsiveCol";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import React from "react";

export function TransactionsLaw() {
  return (
    <ResponsiveCol>
      <h1>特定商取引法に基づく表記</h1>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell align="left">販売業者</TableCell>
              <TableCell align="left">株式会社コルサント</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">運営統括責任者</TableCell>
              <TableCell align="left">代表取締役 笠原誠司</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">所在地</TableCell>
              <TableCell align="left">
                東京都港区浜松町２丁目２番１５号　浜松町ダイヤビル２Ｆ
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">電話番号</TableCell>
              <TableCell align="left">メールにてお問い合わせください</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">メールアドレス</TableCell>
              <TableCell align="left">cs@coruscant.co.jp</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">ホームページ</TableCell>
              <TableCell align="left">
                <a href="https://coruscant.co.jp">https://coruscant.co.jp</a>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">サービス利用料金</TableCell>
              <TableCell align="left">
                プランにより異なります。詳細はサービスページを御覧ください
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">サービス利用料金以外の必要料金</TableCell>
              <TableCell align="left">
                消費税（有料プランをご利用の方のみ）
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">サービスの提供時期・方法</TableCell>
              <TableCell align="left">
                登録手続の完了後直ちに提供します。ただし、銀行振込の場合、入金確認後当社審査完了次第速やかに提供します。
                <br />
                購入完了後、利用停止されるまで、サービスをご利用いただけます。
                <br />
                月額商品について利用継続を希望しない場合はお客様ご自身で利用停止のお手続きを行っていただく必要があります。
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">サービス利用料金支払い方法</TableCell>
              <TableCell align="left">
                決済にはストライプジャパン株式会社
                （以下、Stripe社）が提供する決済システム「Stripe」を利用しており、クレジットカードによりお支払いいただけます。
                <br />
                Stripeの利用条件、引き落とし時期及びその他Stripeに関する事項は、Stripe社が定める規約によるものとします。
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">決済期間</TableCell>
              <TableCell align="left">
                クレジットカード決済の場合はただちに処理されます。
                <br />
                月額商品については、購入完了後、利用停止されるまで、1カ月毎に料金をお支払いいただきます。
                <br />
                年額商品については、購入完了後、利用停止されるまで、１カ年毎に料金をお支払いいただきます。
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">返品・交換・キャンセル等</TableCell>
              <TableCell align="left">
                サービスの性質上、返品、交換はお受けしておりません。
                <br />
                利用期間中に利用されなかった場合、または利用期間終了日前に利用を終了された場合であっても、日割り精算等は行いません。
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </ResponsiveCol>
  );
}
