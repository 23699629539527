import { createTheme, responsiveFontSizes } from "@mui/material";

declare module "@mui/material/styles" {
  interface Palette {
    tertiary: {
      light: string;
      main: string;
      dark: string;
      contrastText: string;
    };
  }

  interface PaletteOptions {
    tertiary: {
      light: string;
      main: string;
      dark: string;
      contrastText: string;
    };
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      light: "#ACB0F2",
      main: "#3039DF",
      dark: "#131759",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ACF3EA",
      main: "#2FE2CB",
      dark: "#125A51",
      contrastText: "#000",
    },
    error: {
      light: "#F1AFAB",
      main: "#DC372E",
      dark: "#581613",
      contrastText: "#fff",
    },
    info: {
      light: "#E2E3E9",
      main: "#78787D",
      dark: "#49494C",
      contrastText: "#fff",
    },
    tertiary: {
      light: "#FFA4FC",
      main: "#FF1BF7",
      dark: "#660C63",
      contrastText: "#000",
    },
    background: {
      default: "#F2F2F7",
    },
  },
  components: {
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingLeft: "8px",
          paddingRight: "8px",
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "36px",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: "small",
      },
    },
  },
  shape: {
    borderRadius: 5,
  },
  typography: {
    fontSize: 14,
    fontFamily: ["Noto Sans", "sans-serif"].join(","),
    h1: {
      fontSize: "2.0rem",
      fontWeight: "bold",
    },
    h2: {
      fontSize: "1.6rem",
      fontWeight: "bold",
    },
    h3: {
      fontSize: "1.3rem",
      fontWeight: "bold",
    },
    h4: {
      fontSize: "1.2rem",
      fontWeight: "bold",
    },
    h5: {
      fontSize: "1.0rem",
      fontWeight: "bold",
    },
    h6: {
      fontSize: "1.0rem",
    },
    button: {
      fontWeight: "bold",
    },
  },
});

export const appTheme = responsiveFontSizes(theme);
