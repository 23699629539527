import { Col } from "./Col";
import { ComponentProps } from "react";
import { Box } from "@mui/material";

type Props = ComponentProps<typeof Box>;
export function ResponsiveCol(props: Props) {
  return (
    <Col
      sx={{
        paddingLeft: {
          xs: "8px",
          sm: "40px",
        },
        paddingRight: {
          xs: "8px",
          sm: "40px",
        },
        paddingTop: {
          xs: "16px",
          sm: "40px",
        },
        paddingBottom: {
          xs: "16px",
          sm: "40px",
        },
        // width: "100%",
        ...props.sx,
      }}
    >
      {props.children}
    </Col>
  );
}
