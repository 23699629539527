import { DateTime } from "luxon";
import { Row } from "../../components/common/Row";
import { Col } from "../../components/common/Col";
import React, {
  ReactElement,
  ReactNode,
  Suspense,
  useEffect,
  useState,
} from "react";
import { Avatar, Box } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import {
  CitationDao,
  MessageDao,
  TenantUserDao,
} from "@chatforce/common/src/dao/firestoreDao";
import { TenantFirestoreService } from "../../models/services/tenantFirestoreService";
import { UserApiClient } from "../../models/apiClients/userApiClient";
import { Timestamp } from "firebase/firestore";
import { ShareScope } from "../../buf/chatforce/user/v1/chat_pb";
import { stringToColor } from "../../utils/stringToColor";
import { ConnectError } from "@connectrpc/connect";
import { ErrorCode } from "@chatforce/common/src/constants/errorCodes";
import { AiModelPluginConfig } from "./ChatScreen";
import Markdown from "react-markdown";
import SyntaxHighlighter from "react-syntax-highlighter";
import { github } from "react-syntax-highlighter/dist/esm/styles/hljs";

const size = "40px";
const UserAvatar = (props: {
  src: string | undefined | null;
  userId: string;
}) => {
  const bgcolor = stringToColor(props.userId);

  const UserAvatarContent = () => {
    const src = props.src;
    if (src === undefined) {
      return <Box sx={{ height: size, width: size }} />;
    }
    if (src === null) {
      return (
        <Avatar
          variant="rounded"
          sx={{ bgcolor, height: size, width: size, alignSelf: "center" }}
        >
          <PersonIcon />
        </Avatar>
      );
    }
    return (
      <Avatar
        variant="rounded"
        src={src}
        sx={{ height: size, width: size, alignSelf: "center" }}
      ></Avatar>
    );
  };
  return (
    <Suspense fallback={<></>}>
      <UserAvatarContent />
    </Suspense>
  );
};
const AssistantAvatar = () => {
  return (
    <Avatar
      variant="rounded"
      src={"/img/cf_icon.svg"}
      sx={{ height: size, width: size, alignSelf: "center" }}
    />
  );
};

const Citation = (props: { citation: CitationDao }) => {
  const citation = props.citation;
  return (
    <Box
      sx={{
        borderStyle: "solid",
        borderColor: "#3039DF",
        borderWidth: "2px",
        borderRadius: "20px",
        paddingTop: "4px",
        paddingBottom: "4px",
        paddingLeft: "16px",
        paddingRight: "16px",
        "&:hover": {
          cursor: citation.citationUrl !== null ? "pointer" : "inherit",
          backgroundColor:
            citation.citationUrl !== null ? "#E2E3E9" : "inherit",
        },
      }}
      onClick={() => {
        if (citation.citationUrl !== undefined && citation.citationUrl !== null)
          window.open(citation.citationUrl, "_blank");
      }}
    >
      [{citation.perPromptSequenceId}] {citation.citationDisplayName}{" "}
      {citation.citationPage ? `p.${citation.citationPage}` : ""}
    </Box>
  );
};

const CodeBlock = ({ node, className, children, ref, ...props }: {
  node: any;
  className: string;
  children: ReactNode;
  ref: any;
  props: any;
}) => {
  const match = /language-(\w+)/.exec(className || "");
  const lang = match && match[1] ? match[1] : '';
  const name = match && match[2] ? match[2].slice(1) : ''
  const code = match ? (
    <Col style={{ borderRadius: "8px", background: "#DDDDDD", width: "100%", flex: 1 }}>
      <Box sx={{ marginLeft: "8px", padding: "8px" }}>{lang}</Box>
      <SyntaxHighlighter
        {...props}
        style={github}
        wrapLines={true}
        customStyle={{
          margin: 0,
          padding: "16px",
          border: "1px solid #DDDDDD",
          width: "100%",
          flex: 1,
          borderRadius: "0 0 8px 8px",
        }}
        lineProps={{ style: { wordBreak: "break-all", whiteSpace: "pre-wrap", padding: 8 } }}
        // lineProps={{
        //   style: { flexWrap: "wrap", wordBreak: "break-word" },
        // // }}
        children={String(children).replace(/\n$/, "")}
        language={match[1]}
      />
    </Col>
  ) : (
    // Inline
    <code {...props} className={className}>
      <strong>{children}</strong>
    </code>
  );
  return code;
};

const ChatCell = (props: {
  ownerName: string;
  dateString: string;
  avatar: ReactNode;
  text: string;
  color: string;
  errorMessage: string | null;
  markdown?: boolean;
  citations: CitationDao[] | null;
}) => {
  const citations: ReactElement[] =
    (props.citations ?? []).map((cite, index) => (
      <Box key={`cite-${index}`} sx={{ margin: "8px" }}>
        <Citation citation={cite} />
      </Box>
    )) ?? [];
  return (
    <Row
      sx={{
        width: "100%",
        paddingLeft: "16px",
        paddingRight: "16px",
        boxSizing: "border-box",
        alignItems: "start",
        justifyContent: "start",
        marginTop: "8px",
        marginBottom: "8px",
      }}
    >
      <Row
        sx={{
          width: "100%",
          paddingLeft: "16px",
          paddingRight: "16px",
          background: props.color,
          borderRadius: "16px",
          alignItems: "start",
          justifyContent: "start",
          boxShadow: 2,
        }}
      >
        <Col sx={{ justifyContent: "start", alignItems: "start" }}>
          <Row sx={{ marginTop: "16px" }}>
            {props.avatar}
            <Col
              sx={{
                alignItems: "start",
                marginLeft: "16px",
                fontSize: "14px",
                fontWeight: "bold",
              }}
            >
              <Row>{props.ownerName}</Row>
              <Row sx={{ fontSize: "8px", color: "gray", marginTop: "4px" }}>
                <Row>{props.dateString}</Row>
              </Row>
            </Col>
          </Row>
          <Box
            sx={{
              marginBottom: "16px",
              maxWidth: "100%",
            }}
          />
          {props.markdown ? (
            <Markdown
              children={props.text}
              components={{
                code: CodeBlock as any
              }}
            />
          ) : (
            <Row
              sx={{
                whiteSpace: "pre-wrap",
              }}
            >
              {props.text}
            </Row>
          )}
          {props.errorMessage !== null ? (
            <Row sx={{ color: "blue" }}>{props.errorMessage}</Row>
          ) : (
            <></>
          )}
          {citations.length > 0 ? (
            <Row sx={{ alignItems: "center" }}>
              <Box sx={{ fontWeight: "bold", marginLeft: "8px" }}>出典:</Box>{" "}
              {citations}
            </Row>
          ) : (
            <></>
          )}
          <Box sx={{ marginBottom: "16px" }} />
        </Col>
      </Row>
    </Row>
  );
};

export const UserMessageCell = (props: {
  tenantId: string;
  message: MessageDao;
}) => {
  const userId = props.message.userId;
  const client = TenantFirestoreService.getInstance();
  const [user, setUser] = useState<TenantUserDao | undefined>(undefined);
  const dateString =
    props.message.createdAt?.seconds === undefined
      ? ""
      : DateTime.fromSeconds(props.message.createdAt.seconds).toFormat(
        "MM月dd日 HH:mm",
      );
  useEffect(() => {
    if (userId === undefined || userId === null) {
      return;
    }
    client.getTenantUser(props.tenantId, userId).then((user) => {
      if (user === null) {
        return;
      }
      setUser(user);
    });
  }, []);
  const src = user === undefined ? undefined : user.userIconUrl;
  return (
    <ChatCell
      ownerName={user?.userDisplayName ?? ""}
      text={props.message.content ?? ""}
      dateString={dateString}
      avatar={<UserAvatar src={src} userId={userId ?? ""} />}
      color={"white"}
      errorMessage={null}
      markdown={false}
      citations={null}
    />
  );
};

export const AssistantMessageCell = (props: {
  message: Partial<MessageDao>;
  errorMessage: string | null;
}) => {
  const dateString =
    props.message.createdAt?.seconds === undefined
      ? ""
      : DateTime.fromSeconds(props.message.createdAt.seconds).toFormat(
        "MM月dd日 HH:mm",
      );
  return (
    <ChatCell
      ownerName={"アシスタント"}
      text={props.message.content ?? ""}
      dateString={dateString}
      avatar={<AssistantAvatar />}
      color={"white"}
      errorMessage={props.errorMessage}
      markdown={true}
      citations={props.message.citations ?? null}
    />
  );
};
export const LiveMessage = (props: {
  conversationId: string | undefined;
  prompt: string;
  aiModelPluginConfig: AiModelPluginConfig;
  shareScope: ShareScope;
  onUpdate: () => void;
  onSuccessfulEnd: (messageId: string, conversationId: string) => void;
  onError: (code: string) => void;
  onComplete: () => void;
}) => {
  const [accumulatedText, setAccumulatedText] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  useEffect(() => {
    setAccumulatedText(null);
    let newConversationId: string | undefined;
    const chatClient = UserApiClient.getInstance();
    const subscription = chatClient
      .queryAssistant(
        props.prompt,
        props.conversationId ?? null,
        props.aiModelPluginConfig.aiModel,
        props.shareScope,
        props.aiModelPluginConfig.plugin?.id ?? null,
      )
      .subscribe({
        next: (response) => {
          switch (response.payload.case) {
            case "start":
              newConversationId = response.payload.value.conversationId;
              break;
            case "chunk":
              const token = response.payload.value.token;
              setAccumulatedText((prevState) => (prevState ?? "") + token);
              break;
            case "end":
              const lastMessageId = response.payload.value.messageId;
              props.onSuccessfulEnd(lastMessageId, newConversationId ?? "");
              break;
            case "error":
              const code = response.payload.value.code;
              console.error(code);
              if (code === ErrorCode.USER_QUOTA_EXCEEDED) {
                setErrorMessage("このAIモデルでの利用上限に達しました。利用を継続されたい場合はプランをアップグレードするか、トークンの追加購入をお願いいたします。");
              } else if (code === ErrorCode.SUBSCRIPTION_EXPIRED) {
                setErrorMessage("契約プランの有効期限が切れています。");
              } else {
                setErrorMessage("メッセージの読み込み中にエラーが発生しました。");
              }
              props.onError(code);
          }
          props.onUpdate();
        },
        error: (err: ConnectError) => {
          props.onError(err.rawMessage);
        },
        complete: () => {
          props.onComplete();
          subscription.unsubscribe();
        },
      });
    return () => {
      subscription.unsubscribe();
    };
  }, []);
  if (!accumulatedText && errorMessage === null) {
    return <div key={"empty-message"}></div>;
  }
  return (
    <AssistantMessageCell
      message={{ content: accumulatedText ?? "", createdAt: Timestamp.now() }}
      errorMessage={errorMessage}
    />
  );
};
