import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ResponsiveCol } from "../../../components/common/ResponsiveCol";
import { Label } from "../../../components/common/Label";

import { useEffect, useState } from "react";
import { AdminApiClient } from "../../../models/apiClients/adminApiClient";
import { getLastMonths } from "../../../utils/dateUtils";
import { AiModelLabel } from "../../../utils/constants";
import { AiModel } from "../../../buf/chatforce/user/v1/chat_pb";
import {
  ListTokenUsageDataByYearResponse,
  ListTokenUsageYearsResponse,
  UserMonthlyUsages,
} from "../../../buf/chatforce/admin/v1/admin_pb";
import { useRecoilValueLoadable } from "recoil";
import { currentLoginTenantQuery } from "../../../AppStates";
import { UnexpectedErrorScreen } from "../../Error/UnexpectedErrorScreen";
import { PlanTypeName } from "@chatforce/common/src/dao/firestoreDao";
import { Row } from "../../../components/common/Row";
import { IdTenant } from "@chatforce/common/src/entities/entities";

const tableCellNameStyle = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const tableCellValueStyle = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textAlign: "right",
};

type TokenUsageTableProps = {
  aiModel: AiModel;
  tokenUsageYears: string[];
  tenant: IdTenant,
};

type Month = {
  yearMonthStr: string;
  date: Date;
};

/**
 * Returns the months based on the given year.
 *
 * - When the year is the current year, return the months from January to the current month.
 * - When the year is the previous year, return all months
 * - When the year is a future year, return an empty array.
 */
const getMonthsByYear = (year: string): Month[] => {
  const now = new Date();
  const nowYear = now.getFullYear();
  const nowMonth = now.getMonth() + 1; // 1-12 for the current month

  // check future year
  if (Number(year) > Number(nowYear)) return [];

  // prepare months count for getLastMonths function
  const mcount = 12 * (Number(nowYear) - Number(year)) + nowMonth;
  const lastMonths = getLastMonths(mcount);

  // sort last months to asc
  let tmpMonths: Month[] = [];
  lastMonths.forEach((m) => {
    tmpMonths = [m, ...tmpMonths];
  });

  // filter last months by year
  return [...tmpMonths.filter((_, i) => i < 12 && i < mcount)];
};

const TokenUsageTable: React.FC<TokenUsageTableProps> = ({
  aiModel,
  tokenUsageYears,
  tenant,
}) => {
  const adminClient = AdminApiClient.getInstance();

  const [tokenUsageUsers, setTokenUsageUsers] = useState<UserMonthlyUsages[]>(
    [],
  );
  const [currentMonthlyQuota, setCurrentMonthlyQuota] = useState<number | undefined>(undefined);
  const [selectedYear, setSelectedYear] = useState<string>(
    String(new Date().getFullYear()),
  );
  const [months, setMonths] = useState<Month[]>([]);

  useEffect(() => {
    if (tokenUsageYears.length > 0 && selectedYear === "") {
      setSelectedYear(tokenUsageYears[0]);
    }
  }, [tokenUsageYears]);

  useEffect(() => {
    adminClient
      .listTokenUsageDataByYear(selectedYear, aiModel)
      .then((res: ListTokenUsageDataByYearResponse) => {
        console.log(res);
        setTokenUsageUsers([...res.userMonthlyUsages]);
        setCurrentMonthlyQuota(res.currentMonthlyTokenQuota);
      });

    setMonths(getMonthsByYear(selectedYear));
  }, [selectedYear]);

  const getTokenCount = (id: string, date: Date) => {
    const user = tokenUsageUsers.find((user) => user.id === id);
    if (!user) return 0;
    const month = user.months.find(
      (month) => month.month === date.getMonth() + 1,
    );
    if (!month) return 0;
    return month.tokens;
  };

  const handleChangeYear = (value: string) => {
    if (selectedYear !== value) setSelectedYear(value);
  };

  return (
    <>
      <Label>{AiModelLabel[aiModel]}</Label>
      <Row><span style={{ "fontWeight": "bold", marginRight: "8px" }}>現プランでの月間テナントトークン上限: </span> {currentMonthlyQuota?.toString() ?? "-"}</Row>
      <Row>※ご契約中のユーザー（{tenant.seats}名）で共有される合算値です</Row>
      <FormControl sx={{ width: 140, marginTop: "16px" }}>
        <InputLabel id="select-month">対象年を選択</InputLabel>
        <Select
          labelId="select-year"
          value={selectedYear}
          label="年を選択"
          onChange={(event) => handleChangeYear(event.target.value)}
        >
          {tokenUsageYears.map((usageYear) => (
            <MenuItem value={usageYear} key={usageYear}>
              {usageYear}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TableContainer
        component={Paper}
        sx={{
          display: "flex",
          width: "100%",
          maxHeight: "calc(100vh - 450px)",
          marginTop: "16px",
          marginBottom: "32px",
        }}
      >
        <Table sx={{ minWidth: "100%", width: "100%" }} size="small">
          <TableHead
            sx={{
              position: "sticky",
              zIndex: 1,
              top: "0",
            }}
          >
            <TableRow
              sx={{
                backgroundColor: "#fff",
              }}
            >
              <TableCell sx={{ ...tableCellNameStyle }}></TableCell>
              {months.map((month, index) => (
                <TableCell
                  key={`month-${index}`}
                  sx={{
                    fontWeight: "bold",
                    ...tableCellValueStyle
                  }}
                >
                  {month.yearMonthStr}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tokenUsageUsers.map((user, index) => {
              return (
                <TableRow key={`table-row-${index}`}>
                  <TableCell
                    sx={{ ...tableCellNameStyle }}
                    component="th"
                    scope="row"
                  >
                    {user.userDisplayName}
                  </TableCell>
                  {months.map((month, index) => (
                    <TableCell
                      key={`table-row-cell-${index}-${user.id}`}
                      sx={{ ...tableCellValueStyle }}
                    >
                      {getTokenCount(user.id, month.date)}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export const TokenUsage = () => {
  const tenantLoadable = useRecoilValueLoadable(currentLoginTenantQuery);
  const state = tenantLoadable.state;
  const adminClient = AdminApiClient.getInstance();
  const [tokenUsageYears, setTokenUsageYears] = useState<string[]>([]);

  useEffect(() => {
    adminClient
      .listTokenUsageYears()
      .then((res: ListTokenUsageYearsResponse) => {
        setTokenUsageYears(res.years);
      });
  }, []);
  if (state !== "hasValue") {
    return <div>Loading...</div>;
  }
  const tenant = tenantLoadable.getValue();
  if (tenant === null || tenant === undefined) {
    return <UnexpectedErrorScreen />;
  }

  const aiModels: { [key in PlanTypeName]: AiModel[] } = {
    "none": [],
    "trial": [AiModel.GPT_4_O_MINI],
    "standard": [AiModel.GPT_4_O_MINI, AiModel.GPT_4_O],
  }
  const tenantAiModels = aiModels[tenant.planType];

  return (
    <ResponsiveCol sx={{ height: "100%" }}>
      <Box sx={{ flex: 1, width: "100%" }}>
        <Typography variant={"h1"} sx={{ marginBottom: "40px" }}>
          トークン利用量レポート
        </Typography>
        {Object.values(tenantAiModels)
          .map((model) => {
            return (
              <TokenUsageTable
                key={`model-${model}`}
                aiModel={Number(model)}
                tenant={tenant}
                tokenUsageYears={tokenUsageYears}
              />
            );
          })}
      </Box>
    </ResponsiveCol>
  );
};
