import { Col } from "../../components/common/Col";
import { Row } from "../../components/common/Row";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import {
  atom,
  useRecoilState,
  useRecoilValueLoadable,
  useSetRecoilState,
} from "recoil";
import Box from "@mui/material/Box";
import {
  AppBar,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Toolbar,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useParams } from "react-router";
import {
  currentLoginTenantQuery,
  currentTenantUserQuery,
} from "../../AppStates";
import { LoadingScreen } from "../Loading/LoadingScreen";
import {
  AiModelInternalToProtoMapping,
  ChatFirestoreService,
} from "../../models/services/chatFirestoreService";
import { BehaviorSubject, Subject } from "rxjs";
import { MessageDao } from "@chatforce/common/src/dao/firestoreDao";
import { IdTenant, IdTenantUser } from "../../entities/entities";
import { NotFoundScreen } from "../Error/NotFoundScreen";
import { AssistantMessageCell, LiveMessage, UserMessageCell } from "./ChatCell";
import { useLocation, useNavigate } from "react-router-dom";
import { Timestamp } from "firebase/firestore";
import { AiModel, ShareScope } from "../../buf/chatforce/user/v1/chat_pb";
import { getAnalytics, logEvent } from "firebase/analytics";
import { MAX_PROMPT_LENGTH } from "@chatforce/common/src/constants/constants";
import { sleep } from "../../utils/sleep";
import { AiModelLabel } from "../../utils/constants";
import { PromptLibraryDialog } from "./PromptLibrary/PromptLibraryDialog";
import { TenantFirestoreService } from "../../models/services/tenantFirestoreService";
import { Plugin, Plugin as ProtoPlugin } from "../../buf/chatforce/user/v1/plugin_pb";

declare type ChatState = "empty" | "loading" | "waiting" | "idle";

export class AiModelPluginConfig {
  aiModel: AiModel;
  plugin: ProtoPlugin | null;

  constructor(aiModel: AiModel, plugin: Plugin | null) {
    this.aiModel = aiModel;
    this.plugin = plugin;
  }

  get key(): string {
    return `${AiModel[this.aiModel]}__${this.plugin?.id ?? "null"}`;
  }

  get aiModelLabel(): string {
    return AiModelLabel[this.aiModel];
  }
}

let isManuallyScrolling = false;

const chatState = atom<ChatState>({
  key: "chatState",
  default: "empty",
});

const promptState = atom<string>({
  key: "promptState",
  default: "",
});

const submitPromptSubject = new Subject<string>();
const triggerScrollSubject = new BehaviorSubject(true);

const canSubmit = (prompt: string) => {
  return prompt.length > 0 && prompt.length <= MAX_PROMPT_LENGTH;
};

const PromptSendButton = (props: { prompt: string; onClick: () => void }) => {
  const [chatStateValue] = useRecoilState(chatState);
  if (chatStateValue === "loading") {
    return (
      <IconButton disabled>
        <MoreHorizIcon />
      </IconButton>
    );
  }
  return (
    <IconButton disabled={!canSubmit(props.prompt)} onClick={props.onClick}>
      <SendOutlinedIcon
        sx={{
          margin: "10px",
        }}
      />
    </IconButton>
  );
};

const ChatPrimer = (props: {
  tenant: IdTenant;
  shareScope: ShareScope;
  setShareScope: (shareScope: ShareScope) => void;
  pluginConfigs: AiModelPluginConfig[];
  selectedPluginConfigKey: string;
  setSelectedPluginConfigKey: (key: string) => void;
}) => {
  const defaultConfigIndex = 0;
  const analytics = getAnalytics();
  const setPrompt = useSetRecoilState<string>(promptState);
  const [openPromptLibrary, setOpenPromptLibrary] = useState<boolean>(false);
  const [configIndex, setConfigIndex] = useState<number>(defaultConfigIndex);

  return (
    <Col
      sx={{
        alignItems: "center",
        justifyContent: "start",
        marginTop: "40px",
        height: "100%",
      }}
    >
      <FormControl>
        <InputLabel id="demo-simple-select-label">AIモデル</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={configIndex}
          label="AIモデル"
          onChange={(value) => {
            const aiModel = props.pluginConfigs[value.target.value as number];
            logEvent(analytics, "select_ai_model", { model: aiModel.key });
            props.setSelectedPluginConfigKey(aiModel.key);
            setConfigIndex(value.target.value as number);
          }}
        >
          {props.pluginConfigs.map((config, index) => {
            const label = `${AiModelLabel[config.aiModel]} ${config.plugin?.displayName ?? ""}`;
            return (
              <MenuItem key={config.key} value={index}>
                {label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <Row sx={{ marginTop: "16px", fontSize: "small", alignItems: "center" }}>
        <Box>
          プライベート
          <br />
        </Box>
        <Switch
          defaultChecked
          value={props.shareScope === ShareScope.TENANT}
          onChange={(_, checked) => {
            const shareScopeValue = checked
              ? ShareScope.TENANT
              : ShareScope.PRIVATE;
            props.setShareScope(shareScopeValue);
          }}
        />
        <Box>全体共有</Box>
      </Row>
      <Row sx={{ marginTop: "16px", alignItems: "center", justifySelf: "end" }}>
        <Button
          variant={"contained"}
          onClick={() => setOpenPromptLibrary(true)}
        >
          テンプレートライブラリ
        </Button>
      </Row>
      <PromptLibraryDialog
        isOpen={openPromptLibrary}
        onClose={() => setOpenPromptLibrary(false)}
        setPrompt={(prompt: string) => setPrompt(prompt)}
      ></PromptLibraryDialog>
    </Col>
  );
};
const Conversation = (props: {
  conversationId: string | undefined;
  userId: string;
  tenant: IdTenant;
}) => {
  const filterPluginConfigs = (
    pluginConfigs: AiModelPluginConfig[],
  ): AiModelPluginConfig[] => {
    const isStandard = props.tenant.planType === "standard";
    // TODO: トライアルでもGPT-4oが選べてしまうbugを修正
    return pluginConfigs.filter((config) => {
      if (
        // GPT-4 は standard プランのみ
        [AiModel.GPT_4_O].includes(config.aiModel)
      ) {
        return isStandard;
      }
      // それ以外は全てのプランで利用可能
      return true;
    });
  };
  const analytics = getAnalytics();
  const setChatState = useSetRecoilState<ChatState>(chatState);
  const vanillaAiModelConfigs: AiModelPluginConfig[] = filterPluginConfigs([
    new AiModelPluginConfig(AiModel.GPT_4_O_MINI, null),
    new AiModelPluginConfig(AiModel.GPT_4_O, null),
  ]);
  const defaultAiModelConfig = vanillaAiModelConfigs[0];
  const [pluginConfigKey, setPluginConfigKey] = useState<string>(
    defaultAiModelConfig.key,
  );
  const navigate = useNavigate();
  const [messages, setMessages] = useState<ReactNode[] | null | undefined>(
    undefined,
  );
  const [shareScope, setShareScope] = useState<ShareScope>(ShareScope.TENANT);

  const findPluginConfigByKey = (
    key: string,
  ): AiModelPluginConfig | undefined => {
    return vanillaAiModelConfigs.find((config) => config.key === key);
  };

  useEffect(() => {
    if (isManuallyScrolling) {
      return;
    }
    triggerScrollSubject.next(true);
  }, [messages]);

  useEffect(() => {
    let newMessages: ReactNode[] = [];
    if (props.conversationId === undefined) {
      setMessages(newMessages);
    } else {
      const chatFirestoreService = ChatFirestoreService.getInstance(
        props.tenant.id,
      );
      chatFirestoreService
        .getConversation(props.conversationId)
        .then((conversation) => {
          const aiModelPluginConfig = new AiModelPluginConfig(
            AiModelInternalToProtoMapping[conversation.aiModel],
            null,
          );
          if (aiModelPluginConfig !== undefined) {
            setPluginConfigKey(aiModelPluginConfig.key);
          }
        });
      chatFirestoreService
        .getMessages(props.conversationId)
        .then((loadedMessages) => {
          if (loadedMessages === null) {
            setMessages(null);
            return;
          }
          newMessages = loadedMessages.map((content, i) => {
            return (
              <Message
                tenantId={props.tenant.id}
                message={content}
                key={`message-${content.id}`}
              />
            );
          });
          setMessages(newMessages);
        });
    }

    const subscription = submitPromptSubject.subscribe((prompt) => {
      const promptMessageDao: MessageDao = {
        id: "tentative-id",
        promptId: "tentative-id",
        role: "user",
        userId: props.userId,
        ownerId: props.userId,
        content: prompt,
        citations: null,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
      } as MessageDao; // TODO: "as" をなくす
      const pluginConfig =
        findPluginConfigByKey(pluginConfigKey) ?? defaultAiModelConfig;
      const promptMessage = (
        <Message
          tenantId={props.tenant.id}
          message={promptMessageDao}
          key={`message-prompt`}
        />
      );
      newMessages = [...newMessages, promptMessage];
      setMessages(newMessages);
      logEvent(analytics, "submit_prompt", {
        model: pluginConfigKey,
        num_messages: (newMessages ?? []).length,
      });
      const liveMessage = (
        <LiveMessage
          key={"live-message" + Date.now()}
          conversationId={props.conversationId}
          prompt={prompt}
          aiModelPluginConfig={pluginConfig}
          shareScope={shareScope}
          onUpdate={() => {
            triggerScrollSubject.next(true);
          }}
          onSuccessfulEnd={(messageId, conversationId) => {
            if (conversationId !== props.conversationId) {
              navigate(`/c/${conversationId}`, { replace: true });
            }
          }}
          onError={async (_error) => {
            setChatState("idle");
            // Scrollが早く呼ばれすぎるのでWorkaround。適切な修正方法がわかったら修正する。
            await sleep(100);
            triggerScrollSubject.next(true);
          }}
          onComplete={() => {
            setChatState("idle");
          }}
        />
      );
      newMessages = [...newMessages, liveMessage];
      setMessages(newMessages);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [props.conversationId, pluginConfigKey, shareScope]);

  if (messages === null) {
    return <NotFoundScreen />;
  }
  if (messages === undefined) {
    return <LoadingScreen />;
  }
  if (messages.length === 0) {
    if (pluginConfigKey !== defaultAiModelConfig.key) {
      // Primerを表示する前に。選択されているモデルを初期化する。if文で無限ループを回避する。
      setPluginConfigKey(defaultAiModelConfig.key);
    }
    return (
      <ChatPrimer
        tenant={props.tenant}
        shareScope={shareScope}
        setShareScope={setShareScope}
        pluginConfigs={vanillaAiModelConfigs}
        selectedPluginConfigKey={pluginConfigKey}
        setSelectedPluginConfigKey={setPluginConfigKey}
      />
    );
  }
  return (
    <Col
      sx={{
        alignItems: "start",
        justifyContent: "start",
        width: "100%",
      }}
    >
      <AppBar
        position="sticky"
        elevation={0}
        sx={{
          background: "#F2F2F7",
          color: "black",
          borderBottom: "solid",
          borderWidth: 1,
          borderColor: "#E2E3E9",
        }}
      >
        <Toolbar variant="dense">
          AIモデル:{" "}
          {findPluginConfigByKey(pluginConfigKey)?.aiModelLabel ?? "unknown"}
        </Toolbar>
      </AppBar>
      {messages}
      <Row sx={{ flex: 1 }} />
    </Col>
  );
};

const Message = (props: { tenantId: string; message: MessageDao }) => {
  if (props.message.role === "user")
    return (
      <UserMessageCell tenantId={props.tenantId} message={props.message} />
    );
  if (props.message.role === "assistant")
    return <AssistantMessageCell message={props.message} errorMessage={null} />;
  return <></>;
};
const PromptInput = (props: {
  tenantUser: IdTenantUser | null | undefined;
}) => {
  const [prompt, setPrompt] = useRecoilState<string>(promptState);
  const [chatStateValue, setChatState] = useRecoilState<ChatState>(chatState);
  const keyEventHandler = async (event: any) => {
    if (event.key === "Enter" && (event.ctrlKey || event.metaKey)) {
      await submit();
    }
  };
  const onChangeText = (event: any) => {
    // setTextInput(event.target.value);
    setPrompt(event.target.value);
  };
  const submit = async () => {
    if (!canSubmit(prompt)) {
      return;
    }
    if (chatStateValue === "loading") {
      return;
    }
    if (props.tenantUser?.tenantId === undefined) {
      return;
    }

    submitPromptSubject.next(prompt);
    setPrompt("");
    setChatState("loading");
    isManuallyScrolling = false;
    triggerScrollSubject.next(true);
  };

  return (
    <Col
      sx={{
        width: "100%",
        borderRadius: "16px 16px 0px 0px",
        marginLeft: "12px",
        marginRight: "12px",
        background: "#F2F2F7",
      }}
    >
      <Row
        sx={{
          alignItems: "end",
          width: "100%",
          paddingBottom: "8px",
        }}
      >
        <TextField
          id="prompt"
          type="text"
          fullWidth={true}
          multiline={true}
          placeholder={"メッセージを入力"}
          onChange={onChangeText}
          onKeyDown={keyEventHandler}
          value={prompt}
          sx={{
            background: "white",
            borderRadius: "12px",
            boxShadow: 8,
          }}
          inputProps={{
            style: {
              marginTop: "10px",
              marginBottom: "10px",
              marginRight: "40px",
            },
            maxLength: MAX_PROMPT_LENGTH,
          }}
        />
        <Box sx={{ position: "absolute", right: "0px", paddingRight: "18px" }}>
          <PromptSendButton prompt={prompt} onClick={submit} />
        </Box>
      </Row>
      <Row
        sx={{
          alignSelf: "end",
          width: "100%",
          marginBottom: "8px",
          paddingLeft: "16px",
          paddingRight: "16px",
          fontSize: "10px",
          color: "#78787D",
          justifyContent: "space-between",
        }}
      >
        <Box>AIは不正確な情報を出力することがあります。</Box>
        <Box>Enterで改行・Ctrl+Enterで送信</Box>
      </Row>
    </Col>
  );
};
export const ChatScreen = () => {
  let containerRef = useRef<HTMLElement | null>(null);
  let footerRef = useRef<HTMLElement | null>(null);
  const { conversationId } = useParams();
  console.log("ConversationId", conversationId);
  const [tenant, setTenant] = useState<IdTenant | undefined | null>(undefined);
  const tenantUser = useRecoilValueLoadable(currentTenantUserQuery);
  const tenantLoadable = useRecoilValueLoadable(currentLoginTenantQuery);
  const userId = tenantUser.getValue()?.uid;
  const location = useLocation();
  const loginTenant = tenantLoadable.getValue();
  useEffect(() => {
    if (loginTenant === undefined || loginTenant === null) {
      return;
    }
    // tenantLoadable からの tenant は 状態が cache されており、refresher を使うのも状態管理が複雑なので、
    // 直接最新のTenant情報を取得する。
    const tenantService = TenantFirestoreService.getInstance();
    tenantService.getTenant(loginTenant.id).then((tenant) => {
      if (tenant === null) {
        setTenant(null);
        return;
      }
      const idTenant: IdTenant = { id: loginTenant.id, ...tenant };
      setTenant(idTenant);
    });
  }, [loginTenant]);
  useEffect(() => {
    let prevPos = 0;
    const scrollDetector = (_: unknown) => {
      const newPos = window.scrollY;
      if (prevPos > newPos) {
        // Scroll up;
        isManuallyScrolling = true;
      }
      prevPos = window.scrollY;
    };
    window.addEventListener("scroll", scrollDetector);
    return () => {
      window.removeEventListener("scroll", scrollDetector);
    };
  }, []);

  useEffect(() => {
    const subscription = triggerScrollSubject.subscribe((value) => {
      if (isManuallyScrolling) {
        return;
      }
      scrollToBottom("auto");
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [triggerScrollSubject]);

  // URLが遷移したときの処理
  useEffect(() => {
    isManuallyScrolling = false;
    scrollToBottom("auto");
  }, [location]);

  const scrollToBottom = (behavior: "smooth" | "auto") => {
    footerRef.current?.scrollIntoView({ behavior });
  };

  if (
    tenantUser.state !== "hasValue" ||
    userId === undefined ||
    tenant === undefined ||
    tenant === null
  ) {
    return <LoadingScreen />;
  }

  return (
    <Col sx={{ height: "100%", width: "100%" }}>
      <Box
        className="chat-container"
        sx={{
          flexGrow: 1,
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
        ref={containerRef}
      >
        <Conversation
          userId={userId}
          conversationId={conversationId}
          tenant={tenant}
        />
        <Box className={"spacer"} sx={{ flex: 1 }} />
        <Box className={"footer"} sx={{ height: "0" }} ref={footerRef} />
        <Row
          sx={{
            position: "sticky",
            width: "100%",
            bottom: 0,
          }}
        >
          <PromptInput tenantUser={tenantUser.getValue()} />
        </Row>
      </Box>
    </Col>
  );
};
