import { GetTenantCustomPolicyResponse } from "../../buf/chatforce/admin/v1/tenant_custom_pb";
import { useRecoilValue } from "recoil";
import { currentLoginTenantQuery } from "../../AppStates";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { LoadingScreen } from "../Loading/LoadingScreen";
import { TenantCustomClient } from "../../models/apiClients/tenantCustomClient";
import { ResponsiveCol } from "../../components/common/ResponsiveCol";
import { Card } from "../../components/common/elements/Card";
import { Row } from "../../components/common/Row";
import { Button, Link, Typography } from "@mui/material";

export const PolicyAgreementScreen = () => {
  const [policy, setPolicy] = useState<
    GetTenantCustomPolicyResponse | undefined
  >(undefined);
  const tenantCustomClient = TenantCustomClient.getInstance();
  const location = useLocation();
  const fromPathName = location.state?.from?.pathname;
  useEffect(() => {
    tenantCustomClient
      .getTenantCustomPolicy()
      .then((policy) => {
        setPolicy(policy);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);
  const tenant = useRecoilValue(currentLoginTenantQuery);
  const navigate = useNavigate();
  const policyUrl = policy?.policy?.url;
  const [urlOpened, setUrlOpened] = useState<boolean>(false);

  if (tenant === undefined || tenant === null) {
    return <LoadingScreen />;
  }
  const onClickAgree = async () => {
    const tenantCustomClient = TenantCustomClient.getInstance();
    await tenantCustomClient.saveTenantCustomPolicyAgreement();
    if (!fromPathName) {
      // refresher();
      // await sleep(1000);
      navigate("/", { replace: true });
    } else {
      navigate(fromPathName, { replace: true });
    }
    // おなじパスだと遷移しないのでリロードする
    navigate(0);
  };
  return (
    <ResponsiveCol>
      <Card
        withBorder
        filled
        sx={{
          padding: "40px",
          alignItems: "center",
          alignSelf: "center",
          width: "600px",
        }}
      >
        <Row sx={{ alignSelf: "start" }}>
          <Typography variant={"h1"} sx={{ marginBottom: "32px" }}>
            {tenant.displayName} 利用規約
          </Typography>
        </Row>
        <Typography variant={"body1"} sx={{ marginBottom: "32px" }}>
          サービスを利用するためにはテナント独自の利用規約に同意する必要があります。
        </Typography>
        <Link
          href={policyUrl}
          target="_blank"
          typography={"h4"}
          onClick={() => setUrlOpened(true)}
        >
          利用規約を表示
        </Link>
        <Button
          variant={"contained"}
          size={"large"}
          sx={{ alignSelf: "center" }}
          style={{ marginTop: "24px" }}
          disabled={!urlOpened}
          onClick={onClickAgree}
        >
          同意して利用を開始する
        </Button>
      </Card>
    </ResponsiveCol>
  );
};
