import { Avatar, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import {
  currentLoginTenantQuery,
  currentTenantUserQuery,
} from "../../AppStates";
import { Row } from "../common/Row";
import { Col } from "../common/Col";
import { TenantFirestoreService } from "../../models/services/tenantFirestoreService";
import { stringToColor } from "../../utils/stringToColor";

interface Props {
  onClick: () => void;
  size: string;
  withProfile: boolean;
}

export const UserAvatar = (props: Props) => {
  return (
    <React.Suspense fallback={<></>}>
      <UserAvatarContent {...props}></UserAvatarContent>
    </React.Suspense>
  );
};
const UserAvatarContent = (props: Props) => {
  const tenantUserState = useRecoilValue(currentTenantUserQuery);
  const tenantState = useRecoilValue(currentLoginTenantQuery);
  const [groupName, setGroupName] = useState<string | undefined>(undefined);
  let displayName = tenantUserState?.userDisplayName;
  let src = tenantUserState?.userIconUrl;

  useEffect(() => {
    const groupId = tenantUserState?.groupId ?? null;
    const tenantId = tenantState?.id ?? null;
    if (groupId === null) return;
    if (tenantId === null) return;
    const tenantService = TenantFirestoreService.getInstance();
    tenantService.getTenantGroup(tenantId, groupId).then((group) => {
      if (group === null) return;
      setGroupName(group.displayName);
    });
  }, []);

  if (tenantUserState === undefined || tenantUserState === null) {
    return <></>;
  }
  if (tenantState === undefined || tenantState === null) {
    return <></>;
  }
  const bgcolor = stringToColor(tenantUserState.uid);
  const UserTitles = (
    <Col
      sx={{
        alignItems: "end",
        marginLeft: "10px",
        flex: 1,
      }}
    >
      <Box
        sx={{
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textAlign: "start",
          fontSize: "10px",
          width: "140px",
        }}
      >
        {tenantState?.displayName}
      </Box>
      <Box
        sx={{
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textAlign: "start",
          fontSize: "10px",
          width: "140px",
        }}
      >
        {groupName}
      </Box>
      <Box
        sx={{
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textAlign: "start",
          fontSize: "14px",
          width: "140px",
        }}
      >
        {displayName}
      </Box>
    </Col>
  );
  const UserAvatarIcon = (
    <Avatar
      variant="rounded"
      src={src ?? undefined}
      alt={displayName ?? undefined}
      sx={{ height: props.size, width: props.size, bgcolor }}
    />
  );

  return (
    <Row
      onClick={props.onClick}
      sx={{ cursor: "pointer", alignItems: "center" }}
    >
      {UserAvatarIcon}
      {props.withProfile ? UserTitles : null}
    </Row>
  );
};
