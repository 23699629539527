// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file chatforce/admin/v1/admin.proto (package chatforce.admin.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { AiModel } from "../../user/v1/chat_pb.js";

/**
 * @generated from enum chatforce.admin.v1.InvitationStatus
 */
export const InvitationStatus = /*@__PURE__*/ proto3.makeEnum(
  "chatforce.admin.v1.InvitationStatus",
  [
    {no: 0, name: "INVITATION_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "INVITATION_STATUS_INVITED", localName: "INVITED"},
    {no: 2, name: "INVITATION_STATUS_REGISTERED", localName: "REGISTERED"},
    {no: 3, name: "INVITATION_STATUS_DELETED", localName: "DELETED"},
  ],
);

/**
 * @generated from enum chatforce.admin.v1.Role
 */
export const Role = /*@__PURE__*/ proto3.makeEnum(
  "chatforce.admin.v1.Role",
  [
    {no: 0, name: "ROLE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "ROLE_USER", localName: "USER"},
    {no: 2, name: "ROLE_ADMIN", localName: "ADMIN"},
    {no: 3, name: "ROLE_SUPER_ADMIN", localName: "SUPER_ADMIN"},
    {no: 4, name: "ROLE_SERVICE_ADMIN", localName: "SERVICE_ADMIN"},
  ],
);

/**
 * @generated from message chatforce.admin.v1.InviteUserRequest
 */
export const InviteUserRequest = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.InviteUserRequest",
  () => [
    { no: 1, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "group_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "role", kind: "enum", T: proto3.getEnumType(Role) },
    { no: 5, name: "resend", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message chatforce.admin.v1.InviteUserResponse
 */
export const InviteUserResponse = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.InviteUserResponse",
  () => [
    { no: 1, name: "invitation", kind: "message", T: UserInvitation },
  ],
);

/**
 * @generated from message chatforce.admin.v1.ListUserAndInvitationsRequest
 */
export const ListUserAndInvitationsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.ListUserAndInvitationsRequest",
  () => [
    { no: 1, name: "tenant_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * @generated from message chatforce.admin.v1.ListUserAndInvitationsResponse
 */
export const ListUserAndInvitationsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.ListUserAndInvitationsResponse",
  () => [
    { no: 1, name: "user_and_invitations", kind: "message", T: UserInvitation, repeated: true },
  ],
);

/**
 * @generated from message chatforce.admin.v1.UserInvitation
 */
export const UserInvitation = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.UserInvitation",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "role", kind: "enum", T: proto3.getEnumType(Role) },
    { no: 5, name: "status", kind: "enum", T: proto3.getEnumType(InvitationStatus) },
  ],
);

/**
 * @generated from message chatforce.admin.v1.DeleteInvitationRequest
 */
export const DeleteInvitationRequest = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.DeleteInvitationRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "tenant_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * @generated from message chatforce.admin.v1.DeleteInvitationResponse
 */
export const DeleteInvitationResponse = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.DeleteInvitationResponse",
  [],
);

/**
 * @generated from message chatforce.admin.v1.UpdateTenantUserRequest
 */
export const UpdateTenantUserRequest = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.UpdateTenantUserRequest",
  () => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "group_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "role", kind: "enum", T: proto3.getEnumType(Role), opt: true },
  ],
);

/**
 * @generated from message chatforce.admin.v1.UpdateTenantUserResponse
 */
export const UpdateTenantUserResponse = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.UpdateTenantUserResponse",
  [],
);

/**
 * @generated from message chatforce.admin.v1.UpdateInvitationRequest
 */
export const UpdateInvitationRequest = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.UpdateInvitationRequest",
  () => [
    { no: 1, name: "invitation_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "group_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "role", kind: "enum", T: proto3.getEnumType(Role), opt: true },
  ],
);

/**
 * @generated from message chatforce.admin.v1.UpdateInvitationResponse
 */
export const UpdateInvitationResponse = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.UpdateInvitationResponse",
  [],
);

/**
 * @generated from message chatforce.admin.v1.AddTenantGroupRequest
 */
export const AddTenantGroupRequest = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.AddTenantGroupRequest",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message chatforce.admin.v1.AddTenantGroupResponse
 */
export const AddTenantGroupResponse = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.AddTenantGroupResponse",
  [],
);

/**
 * @generated from message chatforce.admin.v1.UpdateTenantGroupRequest
 */
export const UpdateTenantGroupRequest = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.UpdateTenantGroupRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "sort_index", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ],
);

/**
 * @generated from message chatforce.admin.v1.UpdateTenantGroupResponse
 */
export const UpdateTenantGroupResponse = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.UpdateTenantGroupResponse",
  [],
);

/**
 * @generated from message chatforce.admin.v1.TenantGroup
 */
export const TenantGroup = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.TenantGroup",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message chatforce.admin.v1.DeleteTenantGroupRequest
 */
export const DeleteTenantGroupRequest = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.DeleteTenantGroupRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message chatforce.admin.v1.DeleteTenantGroupResponse
 */
export const DeleteTenantGroupResponse = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.DeleteTenantGroupResponse",
  [],
);

/**
 * @generated from message chatforce.admin.v1.DeleteTenantUserRequest
 */
export const DeleteTenantUserRequest = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.DeleteTenantUserRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "tenant_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * @generated from message chatforce.admin.v1.DeleteTenantUserResponse
 */
export const DeleteTenantUserResponse = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.DeleteTenantUserResponse",
  [],
);

/**
 * @generated from message chatforce.admin.v1.UpdateTenantKeyRequest
 */
export const UpdateTenantKeyRequest = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.UpdateTenantKeyRequest",
  () => [
    { no: 1, name: "tenant_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message chatforce.admin.v1.UpdateTenantKeyResponse
 */
export const UpdateTenantKeyResponse = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.UpdateTenantKeyResponse",
  [],
);

/**
 * @generated from message chatforce.admin.v1.ListAuditLogsRequest
 */
export const ListAuditLogsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.ListAuditLogsRequest",
  () => [
    { no: 1, name: "start_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "end_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message chatforce.admin.v1.ListAuditLogsResponse
 */
export const ListAuditLogsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.ListAuditLogsResponse",
  () => [
    { no: 1, name: "audit_logs", kind: "message", T: AuditLog, repeated: true },
  ],
);

/**
 * @generated from message chatforce.admin.v1.AuditLog
 */
export const AuditLog = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.AuditLog",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "uid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "action", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "ip_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "timestamp", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message chatforce.admin.v1.ListTokenUsageYearsRequest
 */
export const ListTokenUsageYearsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.ListTokenUsageYearsRequest",
  [],
);

/**
 * @generated from message chatforce.admin.v1.ListTokenUsageYearsResponse
 */
export const ListTokenUsageYearsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.ListTokenUsageYearsResponse",
  () => [
    { no: 1, name: "years", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message chatforce.admin.v1.ListTokenUsageDataByYearRequest
 */
export const ListTokenUsageDataByYearRequest = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.ListTokenUsageDataByYearRequest",
  () => [
    { no: 1, name: "ai_model", kind: "enum", T: proto3.getEnumType(AiModel) },
    { no: 2, name: "year", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message chatforce.admin.v1.ListTokenUsageDataByYearResponse
 */
export const ListTokenUsageDataByYearResponse = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.ListTokenUsageDataByYearResponse",
  () => [
    { no: 1, name: "user_monthly_usages", kind: "message", T: UserMonthlyUsages, repeated: true },
    { no: 2, name: "current_monthly_token_quota", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message chatforce.admin.v1.UserMonthlyUsages
 */
export const UserMonthlyUsages = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.UserMonthlyUsages",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "months", kind: "message", T: MonthlyTokenUsage, repeated: true },
  ],
);

/**
 * @generated from message chatforce.admin.v1.MonthlyTokenUsage
 */
export const MonthlyTokenUsage = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.MonthlyTokenUsage",
  () => [
    { no: 1, name: "month", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "tokens", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "prompts", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message chatforce.admin.v1.UpsertAllowedIpRequest
 */
export const UpsertAllowedIpRequest = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.UpsertAllowedIpRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "ip", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message chatforce.admin.v1.UpsertAllowedIpResponse
 */
export const UpsertAllowedIpResponse = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.UpsertAllowedIpResponse",
  [],
);

/**
 * @generated from message chatforce.admin.v1.ListAllowedIpsRequest
 */
export const ListAllowedIpsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.ListAllowedIpsRequest",
  [],
);

/**
 * @generated from message chatforce.admin.v1.ListAllowedIpsResponse
 */
export const ListAllowedIpsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.ListAllowedIpsResponse",
  () => [
    { no: 1, name: "allowed_ips", kind: "message", T: AllowedIp, repeated: true },
  ],
);

/**
 * @generated from message chatforce.admin.v1.AllowedIp
 */
export const AllowedIp = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.AllowedIp",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_by", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "ip", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "created_at", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message chatforce.admin.v1.DeleteAllowedIpRequest
 */
export const DeleteAllowedIpRequest = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.DeleteAllowedIpRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message chatforce.admin.v1.DeleteAllowedIpResponse
 */
export const DeleteAllowedIpResponse = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.DeleteAllowedIpResponse",
  () => [
    { no: 1, name: "updated", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "current_ip", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message chatforce.admin.v1.GetTenantIpBlockRequest
 */
export const GetTenantIpBlockRequest = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.GetTenantIpBlockRequest",
  [],
);

/**
 * @generated from message chatforce.admin.v1.GetTenantIpBlockResponse
 */
export const GetTenantIpBlockResponse = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.GetTenantIpBlockResponse",
  () => [
    { no: 1, name: "enabled_ip_block", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message chatforce.admin.v1.UpdateTenantIpBlockRequest
 */
export const UpdateTenantIpBlockRequest = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.UpdateTenantIpBlockRequest",
  () => [
    { no: 1, name: "enabled_ip_block", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message chatforce.admin.v1.UpdateTenantIpBlockResponse
 */
export const UpdateTenantIpBlockResponse = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.UpdateTenantIpBlockResponse",
  () => [
    { no: 1, name: "updated", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "current_ip", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

