import { Col } from "../../components/common/Col";
import { Button, Divider, Typography } from "@mui/material";
import React from "react";
import { configs } from "../../models/utils/Configs";
import { ResponsiveCol } from "../../components/common/ResponsiveCol";
import { useNavigate } from "react-router-dom";
import { Card } from "../../components/common/elements/Card";
import { getAnalytics, logEvent } from "firebase/analytics";

export const TopScreen = () => {
  const serviceName = configs.serviceName;
  const navigate = useNavigate();
  const analytics = getAnalytics();
  const onClickLogin = () => {
    navigate("/tenant_select");
    logEvent(analytics, "clicked_tenant_select_button");
  };
  return (
    <ResponsiveCol
      className={"login-box"}
      sx={{
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        height: "100%",
      }}
    >
      <Col
        sx={{
          justifyContent: "center",
          borderRadius: "8px",
          width: "100%",
        }}
      >
        <Typography variant={"h1"} sx={{ marginBottom: "32px" }}>
          {serviceName} へようこそ！
        </Typography>
        <Card withBorder filled>
          <Card
            sx={{
              maxWidth: "600px",
              alignSelf: "center",
            }}
          >
            <Button
              onClick={() => {
                window.open("https://coruscant.co.jp/chatforce/signup");
              }}
              variant="contained"
              size={"large"}
              sx={{ alignSelf: "center", width: "300px" }}
            >
              サインアップ（トライアル申し込み）
            </Button>
            <Divider sx={{ marginTop: "48px" }} />
            <Typography sx={{ marginTop: "48px" }}>
              すでにアカウントをお持ちの方は以下からログインしてください。
            </Typography>
            <Button
              onClick={onClickLogin}
              size={"large"}
              sx={{ alignSelf: "center", marginTop: "24px", width: "300px" }}
            >
              サブドメイン選択へ
            </Button>
          </Card>
        </Card>
      </Col>
    </ResponsiveCol>
  );
};
