import { Col } from "../../components/common/Col";
import React, { FormEvent, useState } from "react";
import { Row } from "../../components/common/Row";
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from "recoil";
import { currentTenantUserQuery } from "../../AppStates";
import { Alert, Button, Snackbar, Typography, IconButton } from "@mui/material";
import { UnexpectedErrorScreen } from "../Error/UnexpectedErrorScreen";
import { StorageService } from "../../models/services/storageService";
import { UploadableAvatar } from "../../components/profile/UploadableAvatar";
import { ResponsiveCol } from "../../components/common/ResponsiveCol";
import { Label } from "../../components/common/Label";
import {
  LoadingButton,
  LoadingButtonState,
} from "../../components/common/elements/LoadingButton";
import { UserApiClient } from "../../models/apiClients/userApiClient";
import { getAnalytics, logEvent } from "firebase/analytics";
import {
  MAX_USER_NAME_LENGTH,
  MIN_USER_NAME_LENGTH,
} from "@chatforce/common/src/constants/constants";
import { ValidatedTextField } from "../../components/common/elements/ValidatedTextField";
import { Card } from "../../components/common/elements/Card";
import { useNavigate } from "react-router-dom";
import { roleLabels } from "../../entities/entities";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export const AccountScreen = () => {
  const tenantUserState = useRecoilValue(currentTenantUserQuery);
  const [iconFileState, setIconFileState] = React.useState<File | undefined>(
    undefined,
  );
  const [displayNameState, setDisplayNameState] = React.useState<string>(
    tenantUserState?.userDisplayName ?? "",
  );
  const [buttonState, setButtonState] =
    useState<LoadingButtonState>("available");
  const [displayNameInputError, setDisplayNameInputError] =
    useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const [openSaveSnackbar, setOpenSaveSnackbar] = useState<boolean>(false);
  const analytics = getAnalytics();
  const navigate = useNavigate();
  const refresher = useRecoilRefresher_UNSTABLE(currentTenantUserQuery);

  if (!tenantUserState?.uid || !tenantUserState.tenantId) {
    return <UnexpectedErrorScreen />;
  }

  const hasError = (): boolean => {
    return displayNameInputError;
  };
  const onChangeDisplayName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayNameState(event.target.value);
  };
  const onSubmit = async (e: FormEvent<any>) => {
    e.preventDefault();
    logEvent(analytics, "clicked_save_user_profile_button");
    setErrorMessage(undefined);
    setButtonState("loading");
    try {
      let iconUrl;
      if (iconFileState !== undefined && iconFileState !== null) {
        const storageService = StorageService.getInstance();
        iconUrl = await storageService.uploadFile(
          tenantUserState.tenantId,
          `users/${tenantUserState?.uid}/icon.jpg`,
          iconFileState,
        );
      }
      const userClient = UserApiClient.getInstance();
      await userClient.updateUser(
        tenantUserState.uid,
        displayNameState,
        iconUrl,
      );
      setButtonState("available");
      setOpenSaveSnackbar(true);
      refresher();
    } catch (e) {
      console.error(e);
      setErrorMessage("保存に失敗しました。時間をおいて再度お試しください。");
      setButtonState("available");
    }
    logEvent(analytics, "saved_user_profile");
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      console.log("Copied to clipboard: ", text);
    }).catch((err) => {
      console.error("Failed to copy: ", err);
    });
  };

  return (
    <Col
      sx={{
        flex: 1,
        height: "100%",
        alignContent: "center",
        justifyContent: "start",
      }}
    >
      <ResponsiveCol>
        <Typography variant={"h1"} sx={{ marginBottom: "40px" }}>
          ユーザー設定
        </Typography>
        <Card withBorder filled>
          <form onSubmit={onSubmit}>
            <Col sx={{ marginBottom: "40px" }}>
              <Label>プロフィール画像</Label>
              <Row>
                <UploadableAvatar
                  src={tenantUserState.userIconUrl ?? undefined}
                  userId={tenantUserState.uid}
                  onUpload={(file) => {
                    setIconFileState(file);
                  }}
                ></UploadableAvatar>
              </Row>
            </Col>
            <Col sx={{ marginBottom: "40px" }}>
              <Label>ユーザー名</Label>
              <Row>
                <ValidatedTextField
                  type="text"
                  variant={"outlined"}
                  value={displayNameState}
                  inputProps={{
                    required: true,
                    minLength: MIN_USER_NAME_LENGTH,
                    maxLength: MAX_USER_NAME_LENGTH,
                  }}
                  watchError={setDisplayNameInputError}
                  onChange={onChangeDisplayName}
                  sx={{
                    width: "300px",
                  }}
                />
              </Row>
            </Col>
            <Col sx={{ marginBottom: "40px" }}>
              <Label>ユーザーID</Label>
              <Row sx={{ alignItems: "center" }}>
                {tenantUserState.uid}
                <IconButton
                  onClick={() => copyToClipboard(tenantUserState.uid)}
                  sx={{ marginLeft: "8px" }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Row>
            </Col>
            <Col sx={{ marginBottom: "40px" }}>
              <Label>ロール</Label>
              <Row>{roleLabels[tenantUserState.role] ?? ""}</Row>
            </Col>
            <Col>
              {errorMessage ? (
                <Alert severity="error" sx={{ marginBottom: "16px" }}>
                  {errorMessage}
                </Alert>
              ) : (
                <></>
              )}
              <Row>
                <LoadingButton
                  type="submit"
                  buttonState={buttonState}
                  disabled={hasError()}
                  variant="contained"
                >
                  保存
                </LoadingButton>
              </Row>
            </Col>
          </form>
        </Card>
      </ResponsiveCol>
      <Snackbar
        open={openSaveSnackbar}
        autoHideDuration={6000}
        onClose={() => {
          setOpenSaveSnackbar(false);
        }}
      >
        <Alert severity="success" variant={"filled"} sx={{ width: "100%" }}>
          設定変更を保存しました。
        </Alert>
      </Snackbar>
    </Col>
  );
};
