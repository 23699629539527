import { getApps, initializeApp } from "firebase/app";
import { firebaseConfig } from "../firebaseConfig";

export const getInitializedApp = () => {
  const apps = getApps();
  if (apps.length === 0) {
    return initializeApp(firebaseConfig);
  } else {
    return apps[0];
  }
};
