import {
  Avatar,
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import MailIcon from "@mui/icons-material/Mail";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import DeleteIcon from "@mui/icons-material/Delete";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import { Link, useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import { ChatFirestoreService } from "../../models/services/chatFirestoreService";
import React, { useEffect, useState } from "react";
import { ConversationDao } from "@chatforce/common/src/dao/firestoreDao";
import { Row } from "../common/Row";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from "recoil";
import {
  chatUsersImgState,
  currentLoginTenantQuery,
  currentTenantUserQuery,
} from "../../AppStates";
import { ComponentPermissionGuard } from "../common/ComponentPermissionGuard";
import { Col } from "../common/Col";
import { getAnalytics, logEvent } from "firebase/analytics";
import { UserAvatar } from "../profile/UserAvatar";
import { ConfirmationDialog } from "../common/elements/ConfirmationDialog";
import { UserApiClient } from "../../models/apiClients/userApiClient";
import { useParams } from "react-router";
import "./DrawerContent.scss";
import { configs } from "../../models/utils/Configs";
import { TenantFirestoreService } from "../../models/services/tenantFirestoreService";
import { Avatars } from "./Avatars";
import { DateTime } from "luxon";

const pageLimit = 30;

const NewChatButton = (props: { close: () => void }) => {
  const analytics = getAnalytics();
  return (
    <Row
      sx={{
        justifyContent: "center",
        alignItems: "center",
        paddingLeft: "10px",
        paddingRight: "10px",
        paddingTop: "10px",
        background: "#F2F2F7",
      }}
    >
      <Link to={`/`} style={{ textDecoration: "none", width: "100%" }}>
        <Button
          variant="outlined"
          sx={{
            width: "100%",
            boxShadow: 2,
            textTransform: "none",
          }}
          onClick={() => {
            logEvent(analytics, "clicked_new_chat_button");
            props.close();
          }}
        >
          New Chat +
        </Button>
      </Link>
    </Row>
  );
};

const ConversationDay = (props: { text: string }) => {
  return (
    <Row
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        gap: "8px",
      }}
    >
      <Typography
        variant={"body1"}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          textAlign: "center",
          fontSize: "14px",
          color: "#837c7c",
          width: "100%",
          paddingTop: "8px",
          paddingBottom: "8px",
        }}
      >
        {props.text}
      </Typography>
    </Row>
  );
};

const LoadMoreButton = (props: { onClick: () => void }) => {
  return (
    <Row
      key={"load-more-button"}
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        gap: "8px",
      }}
    >
      <Button
        sx={{
          width: "100%",
          textTransform: "none",
          color: "#837c7c",
        }}
        onClick={props.onClick}
      >
        更に読み込む
      </Button>
    </Row>
  );
};
const ConversationList = (props: { close: () => void }) => {
  const chatUsersImg = useRecoilValue(chatUsersImgState);
  const setChatUsersImgState = useSetRecoilState(chatUsersImgState);
  const loginTenant = useRecoilValueLoadable(currentLoginTenantQuery);
  const loginUser = useRecoilValueLoadable(currentTenantUserQuery);
  const [conversations, setConversations] = useState<ConversationDao[]>([]);
  const [isLoadMoreButtonVisible, setIsLoadMoreButtonVisible] =
    useState<boolean>(true);
  const [conversationDays, setConversationDays] = useState<DateTime[]>([]);
  const [deleteDialogConversationId, setDeleteDialogConversationId] = useState<
    string | null
  >(null);
  const analytics = getAnalytics();
  const navigate = useNavigate();
  const userApiClient = UserApiClient.getInstance();
  const { conversationId } = useParams();

  const loadConversations = () => {
    const lastConversationDate =
      conversations.length > 0
        ? conversations[conversations.length - 1].updatedAt
        : undefined;

    if (loginTenant.state !== "hasValue") return;
    const tenantId = loginTenant.contents?.id;
    if (tenantId === undefined) return;
    const userId = loginUser.contents?.uid;
    const role = loginUser.contents?.role;
    if (userId === undefined || role === undefined) return;
    const firestoreClient = ChatFirestoreService.getInstance(tenantId ?? null);
    const subscription = firestoreClient
      .watchConversations(userId, role, lastConversationDate, pageLimit)
      .subscribe({
        next(results) {
          if (results.length < pageLimit) setIsLoadMoreButtonVisible(false);
          setConversations([...conversations, ...results]);
        },
        error(err) {
          console.error("error", err);
        },
        complete() {
          console.log("complete");
        },
      });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadConversations();
  }, [loginTenant]);

  useEffect(() => {
    const userIds: string[] = [];
    const tmpConversationSeconds = new Set<number>(); // Using Set to ensure uniqueness
    conversations.forEach((conversation) => {
      // conversation dates
      const updatedAtTimestamp = conversation.updatedAt;
      const updatedAtDateTime = DateTime.fromSeconds(
        updatedAtTimestamp.seconds,
      );
      const updatedAtDay = updatedAtDateTime.startOf("day");
      // Convert DateTime to seconds to ensure uniqueness
      tmpConversationSeconds.add(updatedAtDay.toSeconds());

      // conversation members
      if (conversation.members == null) return;

      const firstUserId = conversation.members[0];
      const secondUserId = conversation.members[1];

      if (firstUserId && userIdNotExist(firstUserId, userIds))
        userIds.push(firstUserId);
      if (secondUserId && userIdNotExist(secondUserId, userIds))
        userIds.push(secondUserId);
    });
    // Sorting the seconds in descending order
    const sortedSeconds = Array.from(tmpConversationSeconds).sort(
      (a, b) => b - a,
    );
    // Converting seconds back to DateTime objects and updating the state
    setConversationDays(sortedSeconds.map((it) => DateTime.fromSeconds(it)));
    if (userIds.length > 0) fetchUserInfo(userIds);
  }, [conversations]);

  const getConversationsByDay = (day: DateTime) => {
    return conversations.filter(
      (it) =>
        it.updatedAt.seconds &&
        DateTime.fromSeconds(it.updatedAt.seconds).startOf("day").equals(day),
    );
  };

  const userIdNotExist = (userId: string, userIds: string[]) => {
    return (
      userIds.findIndex((id) => id === userId) === -1 &&
      chatUsersImg.userId === undefined
    );
  };

  const fetchUserInfo = async (userIds: string[]) => {
    const tenantId = loginTenant.contents?.id;
    if (tenantId === undefined) return;

    const tenantService = TenantFirestoreService.getInstance();
    const users = await tenantService.listTenantUsers(tenantId, userIds);
    setChatUsersImgState(users);
  };

  // undefined: No User
  // null: No image
  // string: user image available
  const getUserImgFromState = (
    userId: string | undefined,
  ): undefined | null | string => {
    if (userId === undefined) return undefined;

    return chatUsersImg[userId];
  };

  return (
    <>
      <List sx={{ width: "100%" }}>
        {conversationDays.map((day, dayIndex) => {
          return (
            <span key={dayIndex}>
              <ConversationDay
                key={`conversation-day-${dayIndex}`}
                text={day.toFormat("yyyy/MM/dd")}
              />
              {getConversationsByDay(day).map((conversation, i) => {
                const userImgOne = conversation.members
                  ? getUserImgFromState(conversation.members[0])
                  : undefined;
                const userImgTwo = conversation.members
                  ? getUserImgFromState(conversation.members[1])
                  : undefined;

                const selected = conversationId === conversation.id;
                const isOwner =
                  conversation.ownerId !== undefined &&
                  conversation.ownerId === loginUser.contents?.uid;
                const canDelete =
                  isOwner ||
                  ["superAdmin", "admin"].includes(loginUser.contents?.role);
                return (
                  <Row
                    key={conversation.id}
                    sx={{
                      alignItems: "center",
                      justifyContent: "stretch",
                      borderRadius: "8px",
                      padding: "8px",
                      paddingRight: 0,
                      height: "40px",
                      backgroundColor: selected ? "#C7C8CD" : "",
                      cursor: "pointer",
                      "&:hover": { backgroundColor: selected ? "" : "#E2E3E9" },
                    }}
                    onClick={() => {
                      navigate(`/c/${conversation.id}`);
                      props.close();
                      logEvent(analytics, "clicked_conversation", {});
                    }}
                  >
                    <Row
                      sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <Avatars
                        imageOne={userImgOne}
                        uidOne={
                          conversation.members
                            ? conversation.members[0]
                            : undefined
                        }
                        imageTwo={userImgTwo}
                        uidTwo={
                          conversation.members
                            ? conversation.members[1]
                            : undefined
                        }
                      />
                      <Typography
                        variant={"body1"}
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          flex: 1,
                        }}
                      >
                        {conversation.title}
                      </Typography>
                      {selected && canDelete ? (
                        <IconButton
                          onClick={() =>
                            setDeleteDialogConversationId(conversation.id)
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      ) : (
                        <></>
                      )}
                    </Row>
                  </Row>
                );
              })}
            </span>
          );
        })}
        {isLoadMoreButtonVisible && (
          <LoadMoreButton onClick={() => loadConversations()} />
        )}
      </List>
      <ConfirmationDialog
        open={deleteDialogConversationId !== null}
        title={"会話の削除"}
        body={"会話を削除して良いですか？"}
        buttonColor={"error"}
        buttonText={"削除する"}
        onConfirm={async () => {
          if (deleteDialogConversationId === null) return;
          await userApiClient.deleteConversation(deleteDialogConversationId);
          setDeleteDialogConversationId(null);
        }}
        onClose={() => setDeleteDialogConversationId(null)}
      />
    </>
  );
};

const TenantSettingsItem = (props: { close: () => void }) => {
  const analytics = getAnalytics();
  return (
    <ListItemButton
      sx={{ paddingLeft: 4 }}
      component={Link}
      to={`/tenant`}
      onClick={() => {
        logEvent(analytics, "clicked_tenant_settings");
        props.close();
      }}
    >
      <ListItemIcon>
        <CorporateFareIcon />
      </ListItemIcon>
      <ListItemText primary={"テナント設定"} />
    </ListItemButton>
  );
};

const TokenUsageReportItem = (props: { close: () => void }) => {
  const analytics = getAnalytics();
  return (
    <ListItemButton
      sx={{ paddingLeft: 4 }}
      component={Link}
      to={`/tenant/report`}
      onClick={() => {
        logEvent(analytics, "clicked_token_usage_report");
        props.close();
      }}
    >
      <ListItemIcon>
        <DataUsageIcon />
      </ListItemIcon>
      <ListItemText primary={"レポート"} />
    </ListItemButton>
  );
};

const FileManagementItem = (props: { close: () => void }) => {
  const analytics = getAnalytics();
  return (
    <ListItemButton
      sx={{ paddingLeft: 4 }}
      component={Link}
      to={`/tenant/files`}
      onClick={() => {
        logEvent(analytics, "clicked_file_management");
        props.close();
      }}
    >
      <ListItemIcon>
        <InsertDriveFileIcon />
      </ListItemIcon>
      <ListItemText primary={"ファイル管理"} />
    </ListItemButton>
  );
};

const AuditLogsItem = (props: { close: () => void }) => {
  const analytics = getAnalytics();
  return (
    <ListItemButton
      sx={{ paddingLeft: 4 }}
      component={Link}
      to={`/tenant/audit-logs`}
      onClick={() => {
        logEvent(analytics, "clicked_audit_logs");
        props.close();
      }}
    >
      <ListItemIcon>
        <HistoryEduIcon />
      </ListItemIcon>
      <ListItemText primary={"監査ログ"} />
    </ListItemButton>
  );
};

const SettingList = (props: { close: () => void }) => {
  const navigate = useNavigate();
  const analytics = getAnalytics();
  const onClickLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        logEvent(analytics, "clicked_logout");
        console.log("successfully signed out");
        props.close();
        navigate("/signedout", { replace: true });
      })
      .catch((error) => {
        console.error("failed to sign out", error);
      });
  };

  const enableFileUpload = configs.features.enableFileUpload;

  return (
    <List component={"div"} disablePadding>
      <ListItemButton
        sx={{ paddingLeft: 4 }}
        component={Link}
        to={"account"}
        onClick={() => {
          logEvent(analytics, "clicked_setting");
          props.close();
        }}
      >
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText primary={"ユーザー設定"} />
      </ListItemButton>
      <ComponentPermissionGuard
        allowedRoles={["admin", "superAdmin", "serviceAdmin"]}
      >
        <TenantSettingsItem close={props.close} />
      </ComponentPermissionGuard>
      <ComponentPermissionGuard
        allowedRoles={["admin", "superAdmin", "serviceAdmin"]}
      >
        <TokenUsageReportItem close={props.close} />
      </ComponentPermissionGuard>
      {enableFileUpload && (
        <ComponentPermissionGuard allowedRoles={["superAdmin"]}>
          <FileManagementItem close={props.close} />
        </ComponentPermissionGuard>
      )}
      <ListItemButton
        sx={{ paddingLeft: 4 }}
        component={Link}
        to={`/about`}
        onClick={() => {
          logEvent(analytics, "clicked_about");
          props.close();
        }}
      >
        <ListItemIcon>
          <MailIcon />
        </ListItemIcon>
        <ListItemText primary={"About"} />
      </ListItemButton>
      <ComponentPermissionGuard allowedRoles={["superAdmin"]}>
        <AuditLogsItem close={props.close} />
      </ComponentPermissionGuard>
      <ListItemButton sx={{ paddingLeft: 4 }} onClick={onClickLogout}>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary={"ログアウト"} />
      </ListItemButton>
    </List>
  );
};
export const DrawerContent = (props: { close: () => void }) => {
  const [isSettingOpened, setIsSettingsOpened] = React.useState(false);
  const analytics = getAnalytics();
  return (
    <Col
      sx={{
        width: 230,
        minHeight: "100vh",
        msOverflowStyle: "none",
        scrollbarWidth: "none",
        alignSelf: "center",
      }}
      role="presentation"
    >
      <Box
        sx={{
          position: "sticky",
          width: "100%",
          top: 0,
          zIndex: 1,
          alignSelf: "flex-end",
        }}
      >
        <NewChatButton close={props.close} />
      </Box>
      <ConversationList close={props.close} />
      <Box sx={{ display: "flex", flex: 1 }} />
      <Box
        sx={{
          position: "sticky",
          width: "100%",
          bottom: 0,
          backgroundColor: "#F2F2F7",
        }}
      >
        <Divider />
        <List>
          <ListItemButton
            id={"setting-list-item"}
            onClick={() => {
              setIsSettingsOpened(!isSettingOpened);
              logEvent(analytics, "toggled_drawer", { open: !isSettingOpened });
            }}
          >
            <Row
              sx={{
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <UserAvatar size={"40px"} onClick={() => { }} withProfile={true} />
              {isSettingOpened ? <ExpandLess /> : <ExpandMore />}
            </Row>
          </ListItemButton>
          <Collapse in={isSettingOpened} timeout={"auto"} unmountOnExit>
            <SettingList close={props.close} />
          </Collapse>
        </List>
      </Box>
    </Col>
  );
};
