import { useRecoilValue } from "recoil";
import { currentLoginTenantQuery } from "../../../AppStates";
import React, { useState } from "react";
import { IdTenantGroup } from "../../../entities/entities";
import { UnexpectedErrorScreen } from "../../Error/UnexpectedErrorScreen";
import { Row } from "../../../components/common/Row";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { EditGroupDialog } from "./EditGroupDialog";
import { AddGroupDialog } from "./AddGroupDialog";
import { ConfirmationDialog } from "../../../components/common/elements/ConfirmationDialog";
import { AdminApiClient } from "../../../models/apiClients/adminApiClient";
import { getAnalytics, logEvent } from "firebase/analytics";

const tableCellStyle = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: "160px",
};

export const TenantGroupTable = (props: {
  groups: IdTenantGroup[];
  onUpdate: () => void;
}) => {
  const loginTenantState = useRecoilValue(currentLoginTenantQuery);
  const [openedEditGroupDialog, setOpenedEditGroupDialog] =
    useState<IdTenantGroup | null>(null);
  const [openedAddGroupDialog, setOpenedAddGroupDialog] =
    useState<boolean>(false);
  const [openedDeleteGroupDialog, setOpenedDeleteGroupDialog] =
    useState<IdTenantGroup | null>(null);
  const tenantId = loginTenantState?.id;
  const analytics = getAnalytics();

  if (tenantId === undefined) return <UnexpectedErrorScreen />;
  const onClickEditGroup = async (group: IdTenantGroup) => {
    logEvent(analytics, "clicked_edit_tenant_group_button");
    setOpenedEditGroupDialog(group);
  };

  const onClickAddGroup = async () => {
    logEvent(analytics, "clicked_add_tenant_group_button");
    setOpenedAddGroupDialog(true);
  };

  const onClickDeleteGroup = async (group: IdTenantGroup | null) => {
    logEvent(analytics, "clicked_delete_group_button");
    setOpenedDeleteGroupDialog(group);
  };

  const handleDelete = async (group: IdTenantGroup | null) => {
    const adminApiClient = AdminApiClient.getInstance();
    const groupId = group?.id;
    if (groupId === undefined) {
      return;
    }
    await adminApiClient.deleteTenantGroup(groupId);
    logEvent(analytics, "deleted_tenant_group");
  };
  const handleDialogClose = () => {
    setOpenedEditGroupDialog(null);
    setOpenedAddGroupDialog(false);
    setOpenedDeleteGroupDialog(null);
  };

  return (
    <Box
      sx={{
        width: "100%",
        alignItems: "start",
      }}
    >
      <TableContainer
        component={Paper}
        sx={{ display: "flex", overflowX: "scroll", width: "100%" }}
      >
        <Box sx={{ display: "flex", width: "100%" }}>
          <Table sx={{ width: "100%" }} size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ ...tableCellStyle, maxWidth: "100px" }}>
                  グループ名
                </TableCell>
                <TableCell sx={{ width: "60px" }}></TableCell>
                <TableCell sx={{ width: "60px" }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.groups.map((group) => {
                return (
                  <TableRow key={group.id} sx={{ height: "50px" }}>
                    <TableCell sx={tableCellStyle}>
                      {group.displayName}
                    </TableCell>
                    <TableCell style={{ textAlign: "end" }}>
                      <Button
                        variant={"contained"}
                        onClick={() => onClickEditGroup(group)}
                      >
                        編集
                      </Button>
                    </TableCell>
                    <TableCell style={{ textAlign: "end" }}>
                      <Button
                        color={"error"}
                        variant={"contained"}
                        onClick={() => onClickDeleteGroup(group)}
                      >
                        削除
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </TableContainer>
      <Row sx={{ marginTop: "20px" }}>
        <Button onClick={onClickAddGroup} variant={"contained"}>
          グループ追加
        </Button>
      </Row>
      <EditGroupDialog
        group={openedEditGroupDialog}
        onClose={() => {
          handleDialogClose();
        }}
        onSaved={() => {
          props.onUpdate();
        }}
      />
      <AddGroupDialog
        groups={props.groups}
        open={openedAddGroupDialog}
        onClose={() => {
          console.log("onClose from AddGroupDialog");
          handleDialogClose();
        }}
        onSaved={() => {
          props.onUpdate();
        }}
      />
      <ConfirmationDialog
        open={openedDeleteGroupDialog !== null}
        title={"グループの削除"}
        body={`「${openedDeleteGroupDialog?.displayName ?? ""}」 を削除します。よろしいですか？`}
        buttonColor={"error"}
        buttonText={"削除"}
        onConfirm={async () => {
          await handleDelete(openedDeleteGroupDialog);
          props.onUpdate();
          handleDialogClose();
        }}
        onClose={() => {
          handleDialogClose();
        }}
      />
    </Box>
  );
};
